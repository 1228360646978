import React, { useState } from 'react';
import { useEffect } from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import type { History } from 'history';
import * as z from 'zod';
import { browserStorage } from 'utils/browser-storage';

export const PREV_HASH_KEY = 'coco:prev-hash';
const REDIR_VALIDITY_THRESHOLD_MS = 1000 * 60;

const PreRedirectPayloadSchema = z.object({
  path: z.string(),
  msse: z.number(),
});

export function ResumableRedirect(props: RedirectProps) {
  const [didInit, setInited] = useState(false);
  useEffect(() => {
    try {
      const payload = PreRedirectPayloadSchema.parse({
        path: location.pathname,
        msse: +new Date(),
      });
      browserStorage.setItem(PREV_HASH_KEY, JSON.stringify(payload));
    } catch (e) {
      console.error(e);
    } finally {
      setInited(true);
    }
  }, []);
  if (!didInit) return null;
  return <Redirect {...props} />;
}

export function resumePreRedirectPath(history: History) {
  try {
    const item = browserStorage.getItem(PREV_HASH_KEY);
    if (!item) return false;
    browserStorage.removeItem(PREV_HASH_KEY);
    const payload = PreRedirectPayloadSchema.parse(JSON.parse(item));
    const msse = +new Date();
    if (msse - payload.msse > REDIR_VALIDITY_THRESHOLD_MS) {
      console.warn('pre-redirect url expired');
      return false;
    }
    if (payload.path) {
      history.push(payload.path.slice(1));
      browserStorage.removeItem(PREV_HASH_KEY);
      return true;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}
