import { AddReactionMessage, ReactionType } from "coco-rtc-shared"
import { currentPeerId } from "./peer-registry"
import { getProjectData } from "./project-data-coordinator"
import { sendWSMessage } from "./rpc-client"

export const emitReaction = (
    reactionType: ReactionType,
    collaboratorId: string
) => {
    const projectData = getProjectData(collaboratorId)
    if (!projectData) {
        throw new Error("Project not available")
    }
    const message: AddReactionMessage = {
        type: "add-reaction",
        reactionType,
        id: currentPeerId!,
        to: collaboratorId,
        projectOwnerId: collaboratorId,
        projectId: projectData.projectId,
        projectSnapshotId: projectData.version,
    }
    sendWSMessage(message)
}
