import React from 'react';
import C from './PreLoginLayout.css';
import PreLoginNavBar from 'components/prelogin-nav-bar/PreLoginNavBar';
import SharedLayout from 'components/shared-layout/SharedLayout';

export const Footer = () => (
  <div className={C.footer}>
    <div className={C.footerBody}>
      <div className={C.tocLinkContainer}>
        <a>Privacy & Terms</a>
        <a>Contact Us</a>
      </div>
    </div>
  </div>
);

const PreLoginLayout: React.FC<{
  header?: React.ReactChild;
  footer?: React.ReactChild;
  children?: React.ReactChild;
  onSuccess?: () => void;
  wide?: boolean;
}> = (p) => {
  return (
    <SharedLayout
      {...p}
      header={p.header ?? <PreLoginNavBar />}
      footer={p.footer ?? <Footer />}
    />
  );
};

export default PreLoginLayout;
