import {
    emitProject,
    ProjectEmitOpts,
    ProjectEmitPriority,
} from "../project-data-coordinator"
import debounce from "lodash/debounce"
import { sendWSMessage } from "../rpc-client"
import { atom } from "../utils/store"
import { initialProjectDetailsDeferred } from "../rtc-handlers"
import isString from "lodash/isString"
import { isCodeSpace } from "../space"

export interface CodeEditorSettings {
    showLineNumbers: boolean
    textSize: number
}

export interface CodeFile {
    isText: true
    path: string
    content: string
}

export interface AssetFile {
    isText: false
    path: string
    remotePath?: string
    // Only present if being uploaded
    content?: Blob
}

export interface CodeEditorState {
    localVersion: number

    files: (CodeFile | AssetFile)[]
    // Whether the preview will auto refresh when
    // editor contents are changed
    isPaused: boolean

    // Whether the preview is paused and a screenshot
    // is shown in place
    isHalted: boolean

    // Local settings - not persisted
    settings: CodeEditorSettings

    // Shared metadata about project
    manifest?: CodeEditorManifest
}

export interface CodeEditorManifest {
    name: string
}

// Current editor code which component will subscribe
// to. This code can be owned by the current user or
// visited collaborator
export const state = atom<CodeEditorState>({
    files: [],
    isPaused: false,
    isHalted: false,
    localVersion: 0,
    settings: {
        showLineNumbers: true,
        textSize: 11,
    },
})

const needsRemotePreview = () => {
    // For the most common case where we are just making small modifications
    // to sketch.js we want to skip remote preview and keep things faster
    const files = state.get().files
    if (files.length !== 3) return true
    for (const f of files) {
        if (f.path === "index.html" && f.isText) {
            if (f.content !== getDefaultHTML()) {
                return true
            }
        } else if (f.path !== "sketch.js" && f.path !== "index.css") {
            return true
        }
    }
    return false
}

const emitEntries = (
    entries: NonNullable<ProjectEmitOpts["entries"]>,
    priority: ProjectEmitPriority = "high"
) => {
    if (!initialProjectDetailsDeferred.didResolve) return
    emitProject({
        priority,
        entries,
        ensurePreview: needsRemotePreview(),
    })
}

const updateContent = (filePath: string, content: string | Blob) => {
    state.update((it) => {
        const file = isString(content)
            ? { path: filePath, isText: true as const, content }
            : { path: filePath, isText: false as const, content }
        let didFind = false
        const files = it.files.map((f) => {
            if (f.path === filePath && f.isText) {
                didFind = true
                return { ...f, ...file } as typeof file
            }
            return f
        })
        if (!didFind) {
            files.push(file)
        }
        return {
            ...it,
            files,
        }
    })
    emitEntries({
        updated: [filePath],
    })
}

const removeContent = (filePath: string) => {
    state.update((it) => {
        return {
            ...it,
            files: it.files.filter((it) => it.path !== filePath),
        }
    })
    emitEntries({
        deleted: [filePath],
    })
}

const updateSettings = (
    update: (prevSettings: CodeEditorSettings) => CodeEditorSettings
) => {
    state.update((it) => ({
        ...it,
        settings: update(it.settings),
    }))
}

const updateTextSize = (textSize: number) => {
    updateSettings((it) => ({
        ...it,
        textSize,
    }))
}

const toggleLineNumbers = () => {
    updateSettings((it) => ({
        ...it,
        showLineNumbers: !it.showLineNumbers,
    }))
}

const textSizeBounds = {
    min: 5,
    max: 50,
}

const decrementTextSize = () => {
    updateSettings((it) => ({
        ...it,
        textSize: Math.max(textSizeBounds.min, it.textSize - 1),
    }))
}

const incrementTextSize = () => {
    updateSettings((it) => ({
        ...it,
        textSize: Math.min(textSizeBounds.max, it.textSize + 1),
    }))
}

const reset = () => {
    initDefault()
}

export const codeEditorStore = {
    state: state,
    updateContent,
    removeContent,
    updateSettings,
    updateTextSize,
    toggleLineNumbers,
    decrementTextSize,
    incrementTextSize,
    reset,
    needsRemotePreview,
}

export const initDefault = () => {
    state.update((it) => {
        return {
            ...it,
            files: [
                {
                    path: "index.html",
                    isText: true,
                    content: getDefaultHTML(),
                },
                {
                    path: "index.css",
                    isText: true,
                    content: getDefaultStyle(),
                },
                {
                    path: "sketch.js",
                    isText: true,
                    content: getDefaultJS(),
                },
            ],
            localVersion: it.localVersion + 1,
        }
    })
    emitProject()
}

export const getCodeArchive = async () => {
    const { default: JSZip } = await import("jszip")
    const zip = new JSZip()
    for (const file of state.get().files) {
        if (file.isText) {
            zip.file(file.path, file.content)
        }
    }
    return zip.generateAsync({ type: "blob" })
}

export const loadCodeArchive = async (archive: ArrayBuffer) => {
    const { default: JSZip } = await import("jszip")
    const zip = new JSZip()
    await zip.loadAsync(archive)
    const filesP: Promise<CodeFile | AssetFile>[] = []
    zip.forEach((path, file) => {
        filesP.push(
            (async () => {
                const content = await file.async("string")
                const linkMatch = content.match(/^@coco:link:(.*)$/)
                if (linkMatch) {
                    return {
                        isText: false,
                        path,
                        remotePath: linkMatch[1],
                    }
                }
                return {
                    path,
                    isText: true,
                    content,
                }
            })()
        )
    })
    const files = await Promise.all(filesP)
    state.update((it) => ({
        ...it,
        files,
        localVersion: it.localVersion + 1,
    }))
}

export const tidyCode = () => {
    const updated: string[] = []
    // @ts-ignore
    import("pretty-js").then(({ default: beautify }) => {
        state.update((it) => {
            try {
                return {
                    ...it,
                    files: it.files.map((f) => {
                        try {
                            if (f.path.match(/\.js$/) && f.isText) {
                                const content = beautify(f.content)
                                if (content !== f.content) {
                                    updated.push(f.path)
                                    return { ...f, content }
                                }
                            }
                        } catch (e) {
                            console.error(e)
                        }
                        return f
                    }),
                    localVersion: it.localVersion + 1,
                }
            } catch (e) {
                console.error("Failed to beautify: ", e)
                return it
            }
        })
    })
    if (updated.length > 0) {
        emitEntries({ updated }, "low")
    }
}

export const getDefaultHTML = () => {
    return `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <script>
        console.log('Hello')
    </script>
    <script src="coco-prelude.js"></script>
    <script src="p5.js"></script>

    <link rel="stylesheet" type="text/css" href="index.css">
    <script src="sketch.js"></script>
  </head>
  <body>
  </body>
</html>`
}

const getDefaultStyle = () => {
    return `
html, body {
  margin: 0;
  padding: 0;
}
canvas {
  display: block;
}`
}

const getDefaultJS = () => {
    return `
function setup() {
  // Create the canvas
  createCanvas();

  // Set colors
  background('white');
  fill(204, 101, 192, 127);
  stroke(127, 63, 120);
}

function draw() {
  ${getRandomShape()}
}`
}
const shapes = [
    "rect(40, 120, 120, 40);",
    "ellipse(240, 240, 80, 80);",
    "triangle(300, 100, 320, 100, 310, 80);",
]

export const getRandomShape = () => {
    const shapeIdx = Math.round(Math.random() * (shapes.length - 1))
    return shapes[shapeIdx]
}

state
    .select((it) => it.settings)
    .subscribe(
        debounce((settings) => {
            if (isCodeSpace() && initialProjectDetailsDeferred.didResolve) {
                sendWSMessage({
                    type: "update-space-config",
                    key: "codeEditorSettings",
                    value: settings,
                })
            }
        }, 1000)
    )
