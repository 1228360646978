import React from 'react';
import C from './PreLoginNavBar.css';
import BoxC from '../../styles/box.css';
import Logo from 'components/logo/Logo';

export default function PreLoginNavBar(p: {
  borderDist?: number;
  centerControls?: React.ReactChild | null;
  rightControls?: React.ReactChild | null;
}) {
  return (
    <div
      className={C.container}
      style={{ padding: p.borderDist ? `0 ${p.borderDist}px` : undefined }}
    >
      <Logo className={C.logoContainer} />
      <div className={BoxC.flexGrow}></div>
      {p.centerControls}
      <div className={BoxC.flexGrow}></div>
      {p.rightControls}
    </div>
  );
}
