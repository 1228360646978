import { userProfileAtom } from 'atoms/user-profile';

const NON_CLEARABLE_KEYS = ['coco:prev-hash', 'coco:passcode', 'coco:anSId', 'coco:lang'];
const USER_PLACEHOLDER = '$user';

class BrowserStorage {
  private selected?: Storage;

  constructor() {
    try {
      if (
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('coco:session')
      ) {
        this.selected = localStorage;
        return;
      }
    } catch (e) {
      console.error(e);
    }
    if (typeof sessionStorage !== 'undefined') {
      this.selected = sessionStorage;
    }
  }

  get current(): Storage | undefined {
    return this.selected;
  }

  get all(): Storage[] {
    return [localStorage, sessionStorage];
  }

  useLocalStorage() {
    if (this.selected === localStorage) return;
    this.transferAll(sessionStorage, localStorage);
    this.clear([sessionStorage]);
    this.selected = localStorage;
    this.selected.setItem('coco:session', 'true');
  }

  transferAll(from: Storage, to: Storage) {
    for (const key in from) {
      if (key.match(/^coco:/)) {
        const val = from.getItem(key);
        if (val != null) to.setItem(key, val);
      }
    }
  }

  selectStorage(staySignedIn: boolean) {
    if (staySignedIn) this.useLocalStorage();
    else this.useSessionStorage();
  }

  useSessionStorage() {
    if (this.selected === sessionStorage) return;
    this.transferAll(localStorage, sessionStorage);
    this.clear([localStorage]);
    this.selected = sessionStorage;
  }

  ensureQualified(key: string) {
    const userIdx = key.indexOf(USER_PLACEHOLDER);
    if (userIdx < 0) return key;
    const userId = userProfileAtom.get().userProfile?.id;
    if (!userId) throw new Error('Current user missing');
    return key.replace(USER_PLACEHOLDER, userId);
  }

  getItem(key: string, opts?: { attemptAll?: boolean; storage?: Storage }) {
    if (opts?.attemptAll) {
      for (const storage of this.all) {
        const val = this.getItem(key, { ...opts, attemptAll: false, storage });
        if (val) {
          return val;
        }
      }
      return null;
    }
    const storage = opts?.storage ?? this.current;
    if (!storage) return null;
    try {
      const qKey = this.ensureQualified(key);
      return storage?.getItem(qKey) ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  setItem(key: string, value: string) {
    try {
      return this.current?.setItem(this.ensureQualified(key), value);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  removeItem(key: string) {
    try {
      return this.current?.removeItem(this.ensureQualified(key));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  clear(storages: Storage[] = [localStorage, sessionStorage]) {
    for (const storage of storages) {
      for (const key in storage) {
        if (key.match(/^coco:/) && NON_CLEARABLE_KEYS.indexOf(key) < 0) {
          console.log('removing key: ', key);
          storage.removeItem(key);
        }
      }
    }
  }
}

export const browserStorage = new BrowserStorage();
