import { resumePreRedirectPath } from 'components/resumable-redirect/ResumableRedirect';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function DefaultPage(p: {
  inferCode?: boolean;
  timeout?: number;
  defaultRoute?: string;
}) {
  const history = useHistory();
  const loc = useLocation();
  useEffect(() => {
    if (resumePreRedirectPath(history)) {
      return;
    }
    if (p.timeout != null) {
      const timer = setTimeout(() => {
        history.push(p.defaultRoute ?? '/app');
      }, p.timeout);
      return () => {
        clearTimeout(timer);
      };
    }
    history.push(p.defaultRoute ?? '/app');
  }, [loc.pathname]);
  return null;
}
