import * as mediasoupClient from 'mediasoup-client';
import { sendWsMessageWithResponse, sendWSMessage } from './rpc-client';
import { CloseProducerRequest, ConnectWebRTCTransportRequest, CreateConsumerResponse, CreateWebRTCTransportRequest, CreateWebRTCTransportResponse, GetRouterCapabilitiesRequest, GetRouterCapabilitiesResponse, JoinRequest, JoinResponse, ProduceRequest, ProducerResponse } from 'coco-rtc-shared';
import { Err } from 'space-lift';
import { MediaKind } from 'mediasoup-client/lib/RtpParameters';
import { Dispatcher } from './actions';
import { CocoLogger } from '@cocoplatform/coco-logger';
import { TransportOptions } from 'mediasoup-client/lib/types';

const PC_PROPRIETARY_CONSTRAINTS =
{
    optional: [{ googDscp: true }]
};

export class MediasoupClient {

    device!: mediasoupClient.types.Device;

    sendTransport!: mediasoupClient.types.Transport;

    recvTransport!: mediasoupClient.types.Transport;

    webcamProducer!: mediasoupClient.types.Producer | null;

    micProducer!: mediasoupClient.types.Producer | null;

    consumers!: Map<string, mediasoupClient.types.Consumer>;

    ws: WebSocket;

    joined: boolean;

    constructor({
        ws,
    }: {
        ws: WebSocket
    }) {
        this.ws = ws;
        this.consumers = new Map();
        this.joined = false;
    }

    close() {
        if (this.sendTransport) {
            this.sendTransport.close();
        }

        if (this.recvTransport) {
            this.recvTransport.close();
        }
    }

    async join(iceServers: TransportOptions['iceServers'] = []) {
        this.device = new mediasoupClient.Device();

        const res: GetRouterCapabilitiesResponse = await sendWsMessageWithResponse<GetRouterCapabilitiesRequest>({
            type: 'get-router-capabilities',
        })

        await this.device.load({ routerRtpCapabilities: res.data });

        this.sendTransport = await this.createSendTransport(iceServers);
        this.recvTransport = await this.createRecvTransport(iceServers);

        await sendWsMessageWithResponse<JoinRequest>({
            type: 'join-media',
            rtpCapabilities: this.device.rtpCapabilities,
            sctpCapabilities: this.device.sctpCapabilities,
        });
        this.joined = true;
    }

    async createSendTransport(iceServers: TransportOptions['iceServers']): Promise<mediasoupClient.types.Transport> {
        const transportInfo: CreateWebRTCTransportResponse = await sendWsMessageWithResponse<CreateWebRTCTransportRequest>({
            type: 'create-webrtc-transport',
            consuming: false,
            forceTcp: false,
            producing: true,
            sctpCapabilities: this.device.sctpCapabilities,
        });

        const {
            transportId: id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters
        } = transportInfo;

        const transport = this.device.createSendTransport(
            {
                id,
                iceParameters,
                iceCandidates,
                dtlsParameters,
                sctpParameters,
                iceServers,
                proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS
            },
        );

        transport.on(
            'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
        {
            sendWsMessageWithResponse<ConnectWebRTCTransportRequest>({
                type: "connect-webrtc-transport",
                transportId: transport.id,
                dtlsParameters
            })

                .then(callback)
                .catch(errback);
        });

        transport.on(
            'produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
                try {
                    // eslint-disable-next-line no-shadow
                    const res: ProducerResponse = await sendWsMessageWithResponse<ProduceRequest>({
                        type: 'produce',
                        kind,
                        rtpParameters,
                        appData,
                        transportId: transport.id,
                    });
                    const { producerId: id } = res;

                    callback({ id });
                }
                catch (error: any) {
                    errback(error);
                }
            });
        transport.on(
            'connectionstatechange',
            (connectionState) => {
                CocoLogger.info(`SendTransportConnectionState::${connectionState}`)
            },
        )

        return transport;
    }


    async createRecvTransport(iceServers: TransportOptions['iceServers']): Promise<mediasoupClient.types.Transport> {
        const transportInfo: CreateWebRTCTransportResponse = await sendWsMessageWithResponse<CreateWebRTCTransportRequest>({
            type: 'create-webrtc-transport',
            consuming: true,
            forceTcp: false,
            producing: false,
            sctpCapabilities: this.device.sctpCapabilities,
        });

        const {
            transportId: id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters
        } = transportInfo;

        const transport = this.device.createRecvTransport(
            {
                id,
                iceParameters,
                iceCandidates,
                dtlsParameters,
                sctpParameters,
                iceServers,
            },
        );

        transport.on(
            'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
        {
            sendWsMessageWithResponse<ConnectWebRTCTransportRequest>({
                type: "connect-webrtc-transport",
                transportId: transport.id,
                dtlsParameters
            })
                .then(callback)
                .catch(errback);
        });

        transport.on(
            'connectionstatechange',
            (connectionState) => {
                CocoLogger.info(`RecvTransportConnectionState::${connectionState}`)
            },
        )


        return transport;
    }


    async enableVideo(track: MediaStreamTrack) {
        if (this.webcamProducer) {
            await this.webcamProducer.replaceTrack({
                track
            });
            return;
        }

        if (!this.device.canProduce('video')) {
            throw Error("cannot produce video")
        }

        let codec = this.device.rtpCapabilities.codecs?.find(c => c.mimeType.toLocaleLowerCase() === 'video/vp8');
        const codecOptions =
        {
            videoGoogleStartBitrate: 1000
        };

        this.webcamProducer = await this.sendTransport.produce(
            {
                track,
                codecOptions,
                codec
            });

        this.webcamProducer.on('transportclose', () => {
            this.webcamProducer = null;
        });

        this.webcamProducer.on('trackended', () => {
            this.disableVideo()
                .catch(() => { });
        });
    }

    async disableVideo() {
        if (!this.webcamProducer) {
            return;
        }

        this.webcamProducer.close();

        sendWsMessageWithResponse<CloseProducerRequest>({
            type: 'close-producer',
            producerId: this.webcamProducer.id,
        });

        this.webcamProducer = null;
    }

    // Either creates a producer or resumes an existing one.
    async enableAudio(track: MediaStreamTrack) {
        if (this.micProducer) {
            this.micProducer.replaceTrack({ track });
            this.micProducer.resume();
            return;
        }

        this.micProducer = await this.sendTransport.produce({
            track,
            codecOptions:
            {
                opusStereo: true,
                opusDtx: true,
                opusFec: true,
                opusNack: true
            }
        });

        this.micProducer.on('transportclose', () => {
            this.micProducer = null;
        });

        this.micProducer.on('trackended', () => {

            this.disableAudio()
                .catch(() => { });
        });
    }

    // Either closes producer or just pauses it.
    async disableAudio(pause = false) {
        if (!this.micProducer)
            return;

        if (pause) {
            this.micProducer.pause();
            return;
        }

        this.micProducer.close();

        try {
            sendWsMessageWithResponse<CloseProducerRequest>({
                type: 'close-producer',
                producerId: this.micProducer.id,
            });
        }
        catch (error) {
            console.error(error)
        }

        this.micProducer = null;
    }

    async consumerResumed({
        consumerId,
    }: {
        consumerId: string
    }) {
        const consumer = this.consumers.get(consumerId);

        if (!consumer) {
            return;
        }

        if (consumer.kind === 'video') {
            Dispatcher.setVideoTrackStatus((consumer.appData as any).peerId, true)
        }
    }

    async consumerClosed({
        consumerId
    }: {
        consumerId: string
    }) {
        const consumer = this.consumers.get(consumerId);

        if (!consumer) {
            return;
        }

        consumer.close();
        this.consumers.delete(consumerId);

        if (consumer.kind === 'video') {
            Dispatcher.setVideoTrackStatus((consumer.appData as any).peerId, false)
        }
    }

    async newConsumer(
        {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
            ackId,
        }: {
            peerId: string,
            producerId: string,
            id: string,
            kind: string,
            rtpParameters: any,
            type: string,
            appData: any,
            producerPaused: boolean,
            ackId: string,
        }
    ) {
        const consumer = await this.recvTransport.consume({
            id,
            producerId,
            kind: kind as MediaKind,
            rtpParameters,
            streamId: `${peerId}`,
            appData: { ...appData, peerId } // Trick.
        })

        this.consumers.set(consumer.id, consumer);

        consumer.on('transportclose', () => {
            this.consumers.delete(consumer.id);
        });

        // const { spatialLayers, temporalLayers } =
        //     mediasoupClient.parseScalabilityMode(
        //         consumer.rtpParameters.encodings?.[0].scalabilityMode);

        sendWSMessage<CreateConsumerResponse>({
            consumerId: consumer.id,
            type: 'consumer-created',
            messageId: ackId,
        });

        if (consumer.kind === 'video') {
            Dispatcher.setVideoTrack(peerId, consumer.track)
        } else if (consumer.kind === 'audio') {
            Dispatcher.setAudioTrack(peerId, consumer.track)
        }

        return consumer;
    }
}