import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useThemeSwitcher = () => {
    const location = useLocation();

    useEffect(() => {
        // If on space-editor page, then set theme to dark
        const theme = location.pathname.endsWith('/editor') ? 'dark' : 'light';
        document.querySelector('html')?.setAttribute('data-theme', theme);
    }, [location]);
};
