import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
// import 'normalize.css';
import 'scratch-gui/src/css/colors.css';
// import '@fontsource/nunito';
// import '@fontsource/nunito/200.css';
import '@fontsource/nunito/400.css';
// import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
// import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';
// import 'scratch-gui/src/css/sweet-alert.global.css';
import 'styles/global.css';
import '!!style-loader!css-loader?modules=false!animate.css';
import { httpClient } from 'coco-rtc-client';
import './build-consts';
import { onReady } from 'utils/dom';
import { disableBrokenConnectionHandling } from 'coco-rtc-client';
import { initialQueryParams } from 'utils/url-util';

async function main() {
  if (ENFORCE_HTTPS) {
    if (location.protocol !== 'https:' && !location.host.match(/^localhost/)) {
      location.href = location.href.replace('http:', 'https:');
      return;
    }
  }

  if (initialQueryParams['bcr-enabled'] === 'false') {
    disableBrokenConnectionHandling();
  }

  ReactDOM.render(<App />, document.getElementById('root'));
}

onReady(main);

(window as any).__httpClient = httpClient;
