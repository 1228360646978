import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { theme } from 'styles/theme';
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith';
import RouteHandler from './routes';
import FullPageLoaderFallback from 'components/full-page-loader-fallback/FullPageLoaderFallback';
import NetworkGuard from 'components/network-guard/NetworkGuard';

const App = (): React.ReactElement => {
  return (
    <FlagsmithProvider options={{
      environmentID: 'FNsDCXeNtsNepHXKyykfWh'
    }} flagsmith={flagsmith}>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <NetworkGuard>
            <Suspense fallback={<FullPageLoaderFallback />}>
              <BrowserRouter>
                <RouteHandler />
              </BrowserRouter>
            </Suspense>
          </NetworkGuard>
        </RecoilRoot>
      </ThemeProvider>
    </FlagsmithProvider>
  );
};

export default App;
