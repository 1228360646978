import { useEffect } from 'react';
import qs from 'query-string';
import swal from 'sweetalert';
import isString from 'lodash/isString';
import { useAuthState } from './auth-state';
import { useHistory } from 'react-router-dom';

export const useInitialQParams = () => {
  const { saveToken, enableEditAcc, setProfileCompletionState } =
    useAuthState();
  const history = useHistory();

  useEffect(() => {
    const query = qs.parse(location.search);
    let didChangeQ = false;

    // token coming from oauth flow
    if (isString(query.token)) {
      saveToken(query.token);
      enableEditAcc(true); // sign in via oauth is eqiv. to sign in via email
      delete query.token;
      didChangeQ = true;
    }

    if (isString(query.profileComplete)) {
      if (query.profileComplete === 'true') {
        setProfileCompletionState('COMPLETE');
        delete query.profileComplete;
      }
    }

    let promise = Promise.resolve();
    if (isString(query.error)) {
      promise = swal({
        title: query.error,
        icon: false as any,
        dangerMode: true,
      });
      delete query.error;
      didChangeQ = true;
    }
    if (!didChangeQ) return;
    promise.then(() => {
      const newPath = `${location.pathname}?${qs.stringify(query)}`;
      history.push(newPath);
    });
  }, []);
};
