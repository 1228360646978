import pick from "lodash/pick"
import compact from "lodash/compact"
import defaults from "lodash/defaults"
import { Peer, PeerProjectSyncState } from "./peer"
import { Dispatcher } from "./actions"
import { vm } from "./vm"
import { ProjectEmitableEntries } from "./project-data-coordinator"

const registry = new Map</* id: */ string, Peer>()
export let currentPeerId: string | undefined

export const usernamePlaceholder = "(Unknown)"

export const setCurrentPeerId = (peerId: string) => {
    currentPeerId = peerId
    if (vm) {
        vm.runtime.coco ??= {}
        vm.runtime.coco.currentPeerId = peerId
        vm.runtime.coco.projectOwnerId = peerId
    }
}

export const remove = (peerId: string) => {
    registry.delete(peerId)
}

export const getCurrentPeer = () => {
    if (!currentPeerId) return null
    return registry.get(currentPeerId)
}

export const ensureCurrentPeerId = () => {
    if (!currentPeerId) throw new Error("Expected current peer to be available")
    return currentPeerId
}

export const ensureCurrentPeer = () => {
    const peer = getCurrentPeer()
    if (!peer) throw new Error("Expected current peer to be available")
    return peer
}

export const getAllPeers = () => {
    return registry.values()
}

export const getPeer = (peerId: string) => {
    return registry.get(peerId)
}


export const updatePeer = (peer: Partial<Peer> & { id: string }) => {
    const prevPeer = registry.get(peer.id)
    const newPeer = defaults(
        prevPeer
            ? pick(peer, [
                "streamStatus",
                "image",
                "name",
                "personaId",
                "projectData",
                "userType",
                "userId",
                "projectId",
                "spaceSessionId",
                "isBroadcastingAudio",
                "isBroadcastingVideo",
                "isBroadcastingDisplay",
                "isLive",
                "isHost",
                "isReal",
                "isRemoved",
            ])
            : peer,
        prevPeer,
        { connections: {}, dataChannels: {}, tracks: {} }
    )
    registry.set(newPeer.id, newPeer)
    if (newPeer.isCurrent) {
        currentPeerId = newPeer.id
    }
    return newPeer
}

export const ensurePeer = (peerId: string) => {
    const peer = getPeer(peerId)
    if (!peer) throw new Error(`No peer found for id: ${peerId}`)
    return peer
}


export const markSpaceLocked = () => {
    Dispatcher.markSpaceLocked()
}

export const updateSyncState = (
    id: string,
    update: (prev?: PeerProjectSyncState) => PeerProjectSyncState
) => {
    const peer = getPeer(id)
    if (!peer) return
    peer.syncState = update(peer?.syncState)
    console.log("Updated sync state: ", peer.syncState)
}

const vToEntriesMapping = new Map<
    string,
    {
        trackedAt: number
        entries: ProjectEmitableEntries
    }
>()

export const markVersionAwaited = (
    version: string,
    entries: ProjectEmitableEntries,
    trackedAt: number
) => {
    vToEntriesMapping.set(version, {
        trackedAt,
        entries,
    })
}

export const markVersionSynced = (version: string) => {
    const entry = vToEntriesMapping.get(version)
    if (!entry) return
    const { trackedAt, entries } = entry
    updateSyncState(ensureCurrentPeerId(), (prev) => {
        const next = {
            ...prev,
            entries: {
                ...prev?.entries,
            },
        }
        if (entries?.deleted) {
            for (const assetPath of entries.deleted) {
                delete next.entries[assetPath]
            }
        }
        if (entries?.updated) {
            for (const assetPath of entries.updated) {
                const luAt = next.entries[assetPath]?.locallyUpdatedAt
                if (luAt && luAt < trackedAt) {
                    next.entries[assetPath] = {
                        ...next.entries[assetPath],
                        locallyUpdatedAt: undefined,
                    }
                }
            }
        }
        return next
    })
}

export const getPeerEntries = (peerId: string) => {
    const peer = ensurePeer(peerId)
    return Object.keys(peer.syncState?.entries ?? {})
}
