import * as z from 'zod';
import compact from 'lodash/compact';
import qs from 'query-string';
import {
  Space,
  SpaceQueryInput,
  SpaceQueryResult,
  SpaceSchema,
} from 'coco-rtc-shared';
import { useEffect, useMemo } from 'react';
import { httpClient } from 'coco-rtc-client';
import { SyncState } from 'utils/sync-state';
import { reportServerError } from 'utils/report-error';
import { atom, useRecoilState } from 'recoil';
import uniq from 'lodash/uniq';
import { browserStorage } from 'utils/browser-storage';
import { Evt } from 'evt';
import { sortBy } from 'lodash';

export interface SpaceSectionPreset {
  id: string;
  label: string;
  isDefault?: boolean;
  query?: Partial<SpaceQueryInput>;
}

export type SortDir = 'asc' | 'desc';

export interface SortCriteria {
  label: string;
  column: string;
  dir: SortDir;
  isDefault?: boolean;
}

export interface SortInput {
  column: string;
  dir: SortDir;
}

export interface SpaceSection {
  id: string;
  label: string;
  description?: string;
  showRecencyTag?: boolean;
  presets: SpaceSectionPreset[];
  sortCriteria: SortCriteria[];
}

export const spaceMappingAtom = atom({
  key: 'spaces',
  default: {} as Record<string, Space>,
});

export const spaceSections: SpaceSection[] = [
  {
    id: 'new-spaces-to-join',
    description: 'Any new CoCo spaces that you have been invited to join',
    label: 'New Spaces to Join',
    presets: [
      {
        id: 'all',
        label: 'All',
        query: {
          isNewSpaceToJoin: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: 'Latest',
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
    ],
  },
  {
    id: 'my-coco-spaces',
    description: 'All your CoCo spaces',
    label: 'My CoCo Spaces',
    presets: [
      {
        id: 'all',
        label: 'All',
        query: {
          isMySpace: true,
        },
      },
      {
        id: 'hosting',
        label: 'Created',
        query: {
          isMySpace: true,
          isHosting: true,
        },
      },
      {
        id: 'participated',
        label: 'Participated',
        query: {
          isMySpace: true,
          isParticipating: true,
        },
      },
      {
        id: 'bookmarked',
        label: 'Starred',
        query: {
          isMySpace: true,
          isBookmarked: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: 'Latest',
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
      /*{
        label: 'Most popular',
        column: 'bookmarkCount',
        dir: 'desc',
      },*/
    ],
  },
  {
    id: 'explore-other-spaces',
    description: 'CoCo spaces created by your peers',
    label: 'Explore other spaces',
    presets: [
      {
        id: 'all',
        label: 'All',
        query: {
          spacesByOthers: true,
        },
      },
      {
        id: 'invited',
        label: 'Invited',
        query: {
          spacesByOthers: true,
          isInvited: true,
        },
      },
      {
        id: 'bookmarked',
        label: 'Starred',
        query: {
          spacesByOthers: true,
          isBookmarked: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: 'Latest',
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
      /*{
        label: 'Most popular',
        column: 'bookmarkCount',
        dir: 'desc',
      },*/
    ],
  },
];

const spaceSummaryKey = (id: string) => `coco:u:$user:space:summary:${id}`;

const spaceQueryResultsKey = (params: SpaceQueryParams) => {
  return compact([
    'coco:u:$user',
    `q:${params.query}`,
    params.communityId ? `C:${params.communityId}` : null,
    params.userId ? `U:${params.userId}` : null,
    compact(params.filter).join('|'),
    params.sortCriteria,
  ]).join(':');
};

export const getLocallyPersistedSpaceSummary = (id: string) => {
  const raw = browserStorage.getItem(spaceSummaryKey(id));
  if (!raw) return null;
  try {
    return SpaceSchema.parse(JSON.parse(raw));
  } catch (e) {
    console.error(e);
    return null;
  }
};

interface SpaceQueryParams {
  sectionId: string;
  communityId?: string;
  filter: [string, string];
  sortCriteria: string;
  sortDir: SortDir;
  userId?: string;
  query?: string;
  themeIds?: string[];
}

interface SpaceParams {
  spaceId?: string;
}

export const useSpaceSummary = ({ spaceId }: SpaceParams) => {
  const [spaceMapping, setSpaceMapping] = useRecoilState(spaceMappingAtom);
  const [, setSpaceQueryState] = useRecoilState(spaceQueryStateAtom);
  const space: Space = spaceId ? spaceMapping[spaceId] : null;

  const replaceLocal = (space: Space) => {
    setSpaceMapping((spaces) => ({
      ...spaces,
      [space.id]: space,
    }));
  };

  const patchLocal = (id: string, patch: (space: Space) => Space) => {
    setSpaceMapping((spaces) => {
      const space = spaces[id];
      if (!space) return spaces;
      return {
        ...spaces,
        [id]: patch(space),
      };
    });
  };

  const setLocallyBookmarked = (isBookmarked: boolean) => {
    if (!spaceId) return;
    setSpaceMapping((spaces) => ({
      ...spaces,
      [spaceId]: {
        ...spaces[spaceId],
        isBookmarked,
        bookmarkCount: Math.max(
          0,
          (spaces[spaceId].bookmarkCount ?? 0) + (isBookmarked ? 1 : -1),
        ),
      },
    }));
  };

  const toggleBookmark = async () => {
    if (!space) throw new Error('Space not available');
    if (space.isBookmarked) {
      setLocallyBookmarked(false);
      try {
        await httpClient.delete(`/spaces/${spaceId}/bookmark`);
      } catch (error) {
        reportServerError({
          error,
          title: 'Failed to remove space bookmark',
        });
        setLocallyBookmarked(true);
      }
    } else {
      setLocallyBookmarked(true);
      try {
        await httpClient.post(`/spaces/${spaceId}/bookmark`);
      } catch (error) {
        reportServerError({
          error,
          title: 'Failed to bookmark space',
        });
        setLocallyBookmarked(false);
      }
    }
  };

  const conclude = async (opts?: {
    end?: boolean;
    lock?: boolean;
    archive?: boolean;
    delete?: boolean;
  }) => {
    if (!spaceId) return;
    try {
      await httpClient.delete(`/spaces/${spaceId}?${qs.stringify(opts ?? {})}`);
      if (opts?.delete) {
        setSpaceMapping(({ [spaceId]: _deletedSpace, ...spaces }) => spaces);
      } else {
        setSpaceMapping(({ [spaceId]: s, ...spaces }) => ({
          ...spaces,
          [spaceId]: {
            ...s,
            archivedAt: opts?.archive ? +new Date() : undefined,
            endedAt: +new Date(),
          },
        }));
      }
      setSpaceQueryState((qs) =>
        Object.fromEntries(
          Object.entries(qs).map(([key, mapping]) => {
            if (!mapping) return [key, mapping];
            const idx = mapping.spaceIds.indexOf(spaceId);
            if (idx >= 0) {
              return [
                key,
                {
                  ...mapping,
                  count: mapping.count - 1,
                  spaceIds: mapping.spaceIds.filter((it) => it !== spaceId),
                },
              ];
            }
            return [key, mapping];
          }),
        ),
      );
    } catch (error) {
      reportServerError({
        error,
        title: 'Failed to archive space',
      });
    }
  };

  const restore = async () => {
    if (!spaceId) return;
    try {
      await httpClient.post(`/spaces/${spaceId}/restore`);
      setSpaceMapping(({ [spaceId]: s, ...spaces }) => ({
        ...spaces,
        [spaceId]: {
          ...s,
          archivedAt: undefined,
          endedAt: +new Date(),
        },
      }));
    } catch (error) {
      reportServerError({
        error,
        title: 'Failed to restore space',
      });
    }
  };

  const refetch = async (opts?: { visit: boolean }) => {
    try {
      const { data: space } = await httpClient.get(`/spaces/${spaceId}`, {
        params: {
          visit: opts?.visit ? 'true' : undefined,
        },
      });
      replaceLocal(space);
    } catch (error: any) {
      reportServerError({
        title: 'Failed to fetch space details',
        error,
      });
    }
  };

  return {
    space,
    toggleBookmark,
    conclude,
    restore,
    refetch,
    replaceLocal,
    patchLocal,
  };
};

interface SpaceQueryState {
  syncState: SyncState;
  count: number;
  resultsKey?: string | null;
  spaceIds: string[];
}

const defaultSpaceQueryState: SpaceQueryState = {
  syncState: 'pending',
  count: 0,
  spaceIds: [],
};

const spaceQueryStateAtom = atom({
  key: 'spaceQueryState',
  default: {} as Record<string, SpaceQueryState | undefined>,
});

export const useSpacesQuery = (
  params: SpaceQueryParams,
  sharedFetchParams?: { limit: number },
) => {
  const queryKey = `${params.communityId}:${params.sectionId}`;

  const [spaceQueryState, setSpaceQueryState] =
    useRecoilState(spaceQueryStateAtom);

  const curSpaceQueryState =
    (queryKey ? spaceQueryState[queryKey] : null) ?? defaultSpaceQueryState;

  const setCurSpaceQueryState = (
    update: (s: SpaceQueryState) => SpaceQueryState,
  ) =>
    setSpaceQueryState((mapping) =>
      queryKey
        ? {
          ...mapping,
          [queryKey]: update(mapping[queryKey] ?? defaultSpaceQueryState),
        }
        : mapping,
    );

  const { syncState, count, spaceIds, resultsKey } = curSpaceQueryState;

  const setSyncState = (syncState: SyncState) =>
    setCurSpaceQueryState((s) => ({ ...s, syncState }));

  const setCount = (count: number) =>
    setCurSpaceQueryState((s) => ({ ...s, count }));

  const setResultsKey = (resultsKey?: string | null) =>
    setCurSpaceQueryState((s) => ({ ...s, resultsKey }));

  const setSpaceIds = (update: (spaceIds: string[]) => string[]) =>
    setCurSpaceQueryState((s) => ({ ...s, spaceIds: update(s.spaceIds) }));

  const [spaceMapping, setSpaceMapping] = useRecoilState(spaceMappingAtom);

  const spaces = useMemo(() => {
    return compact(spaceIds.map((id) => spaceMapping[id]));
  }, [spaceIds, spaceMapping]);

  useEffect(() => {
    if (syncState === 'loaded') {
      locallyPersistQueryResults(spaceIds, params);
    }
  }, [syncState, spaceIds]);

  const updateSpaceMapping = (spaces: Space[], overwrite = true) => {
    setSpaceMapping((prevMapping) => {
      const newMapping = { ...prevMapping };
      for (const space of spaces) {
        if (!newMapping[space.id] || overwrite) newMapping[space.id] = space;
        locallyPersistSpace(space);
      }
      return newMapping;
    });
  };

  const offset = spaces.length;

  const restoreLocal = () => {
    const spaces = assimilateLocallyPersistedQueryResults(params);
    if (!spaces) return;
    setSpaceIds(() => spaces.map((space) => space.id));
    updateSpaceMapping(spaces, false);
  };

  const fetchSpaces = async (
    handleResult: (result: SpaceQueryResult) => void,
    extraParams?: Partial<SpaceQueryInput>,
  ) => {
    setSyncState('loading');
    try {
      const resp = await httpClient.get('/spaces', {
        params: {
          ...spaceSections
            .find((section) => {
              return section.id === params.filter[0];
            })
            ?.presets?.find((preset) => {
              return preset.id === params.filter[1];
            })?.query,
          includeCollaborators: true,
          sortBy: params.sortCriteria,
          sortDir: params.sortDir,
          offset,
          communityId: params.communityId,
          query: params.query,
          participantId: params.userId,
          themeIds: params.themeIds,
          limit: sharedFetchParams?.limit,
          ...extraParams,
        },
      });
      const result = resp.data as SpaceQueryResult;
      setSyncState('loaded');
      handleResult(result);
    } catch (error) {
      setSyncState('failed');
      reportServerError({
        title: 'Failed ',
        error,
      });
    }
  };

  const fetchInitialSpaces = async (opts?: { skipIfLoaded: boolean }) => {
    const nextResultsKey = spaceQueryResultsKey(params);
    if (
      opts?.skipIfLoaded &&
      syncState === 'loaded' &&
      resultsKey === nextResultsKey
    ) {
      return;
    }
    if (syncState !== 'loaded') {
      setCount(0);
      restoreLocal();
    }
    fetchSpaces(
      (result) => {
        setCount(result.count ?? 0);
        let spaceIds = result.spaces.map((space) => space.id);
        if (params.sectionId === 'new-spaces-to-join') {
          // Move spaces where you are invited to join to the top
          const invitedSpacesIds = sortBy(result.spaces.filter((space) => {
            return space?.isCollaborator;
          }), [function (o) { return -o.createdAt; }]).map((space) => space.id);

          const otherSpacesIds = sortBy(result.spaces.filter((space) => {
            return !space?.isCollaborator;
          }), [function (o) { return -o.createdAt; }]).map((space) => space.id);

          spaceIds = invitedSpacesIds.concat(otherSpacesIds);
        }
        updateSpaceMapping(result.spaces);
        setResultsKey(nextResultsKey);
        setSpaceIds(() => spaceIds);
      },
      {
        offset: 0,
      },
    );
  };

  const fetchNextSpaces = async () => {
    if (syncState === 'loading') return;
    if (count != null && offset >= count) return;
    const resultsKey = spaceQueryResultsKey(params);

    fetchSpaces((result) => {
      if (offset === 0) {
        const spaceIds = result.spaces.map((space) => space.id);
        updateSpaceMapping(result.spaces);
        setSpaceIds(() => spaceIds);
      } else {
        setSpaceIds((spaceIds) => {
          return uniq(spaceIds.concat(result.spaces.map((space) => space.id)));
        });
        updateSpaceMapping(result.spaces);
      }
      setResultsKey(resultsKey);
    });
  };

  return {
    data: { count, spaces },
    params,
    hasMore: count != null && count > spaces.length,
    syncState,
    fetchNextSpaces,
    fetchInitialSpaces,
  };
};

export type SpaceQueryHandle = ReturnType<typeof useSpacesQuery>;

const assimilateLocallyPersistedQueryResults = (params: SpaceQueryParams) => {
  const key = spaceQueryResultsKey(params);
  const raw = key ? browserStorage.getItem(key) : null;
  if (!raw) return null;
  const spaceIds = z.string().array().parse(JSON.parse(raw));
  const spaces: Space[] = [];
  for (const id of spaceIds) {
    const space = getLocallyPersistedSpaceSummary(id);
    if (!space) return null;
    spaces.push(space);
  }
  return spaces;
};

const locallyPersistQueryResults = (
  spaceIds: string[],
  params: SpaceQueryParams,
) => {
  const key = spaceQueryResultsKey(params);
  if (!key) return;
  browserStorage.setItem(key, JSON.stringify(spaceIds));
};

const locallyPersistSpace = (space: Space) => {
  try {
    browserStorage.setItem(spaceSummaryKey(space.id), JSON.stringify(space));
  } catch (e) {
    console.error('Failed to locally persist space');
    console.error(e);
  }
};

export const spaceCreatedEvt = new Evt<{ space: Space }>();
export const spaceEndedEvt = new Evt<{ space: Space }>();
