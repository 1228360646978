import React from 'react';
import { updateNetworkStatus } from 'utils/events/network-status';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import C from './NetworkGuard.css';

export default class NetworkGuard extends React.Component<{
  children: React.ReactChild;
}> {
  state = { isOnline: true };

  componentDidMount() {
    updateNetworkStatus.attach(({ isOnline }) => {
      this.setState({ isOnline });
    });
  }

  componentDidCatch(error: any) {
    if (error?.name === 'ChunkLoadError') {
      this.setState({ isOnline: false });
    }
  }

  render() {
    if (!this.state.isOnline) {
      return (
        <div className={C.container}>
          <div style={{ flexGrow: 1 }}>
            <div className={C.line}>
              <CloudOffIcon
                style={{
                  width: '4rem',
                  height: '4rem',
                  color: 'var(--color-subtext)',
                }}
              />
            </div>
            <div className={C.line} style={{ lineHeight: '5rem' }}>
              Looks like you lost your connection. Please check it and try
              again.
            </div>
            <div className={C.line}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<ReplayIcon />}
                onClick={() => {
                  location.reload();
                }}
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
