import { AuthError, AccessError, SessionError } from "coco-rtc-shared"

export const Types = {
    AUTH_ERROR: "coco-rtc/ws/AUTH_ERROR",
    ACCESS_ERROR: "coco-rtc/ws/ACCESS_ERROR",
    SESSION_ERROR: "coco-rtc/ws/SESSION_ERROR",
}

export const receiveAuthError = (authError: AuthError) => ({
    ...authError,
    type: Types.AUTH_ERROR,
})

export const receiveSessionError = (sessionError: SessionError) => ({
    ...sessionError,
    type: Types.SESSION_ERROR,
})

export const receiveAccessError = (accessError: AccessError) => ({
    ...accessError,
    type: Types.ACCESS_ERROR,
})

// The shape of actions mirroor the shape of error
export type ErrorAction = AuthError | AccessError | SessionError

export const errorActionCreators = {
    receiveAuthError,
    receiveSessionError,
    receiveAccessError,
}
