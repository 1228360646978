import React, { CSSProperties } from 'react';
import C from './SharedLayout.css';
import cc from 'classcat';
import Helmet from 'react-helmet';

const SharedLayout: React.FC<{
  header?: React.ReactChild;
  footer?: React.ReactChild;
  body?: React.ReactChild;
  children?: React.ReactChild;
  onSuccess?: () => void;
  wide?: boolean;
  style?: CSSProperties;
  className?: string;
}> = (p) => {
  return (
    <div
      className={cc([
        {
          [C.pageContainer]: true,
          [C.wide]: p.wide,
          [C.narrow]: !p.wide,
        },
        p.className,
      ])}
      style={p.style}
    >
      <Helmet>
        <title>CoCo - Being. Creative. Together.</title>
      </Helmet>
      {p.header}
      {p.body ?? (
        <div className={C.pageInner}>
          <div className={C.pageBody}>{p.children}</div>
        </div>
      )}
      {p.footer}
    </div>
  );
};

export default SharedLayout;
