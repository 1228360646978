import axios from "axios"


export const getBaseUrl = (
    envHost?: string,
    envPort?: string,
    envSSLEnabled?: boolean
) => {
    const proto = "http" + (envSSLEnabled ? "s" : "")
    return `${proto}://${envHost}:${envPort}`
}

export const getServerBaseUrl = () =>
    getBaseUrl(
        process.env.SERVER_HOST,
        process.env.SERVER_PORT,
        process.env.SERVER_SSL_ENABLED === "1",
    )

export const getPublicAssetBucketUrl = () => `https://${process.env.PUBLIC_ASSET_BUCKET}.s3.amazonaws.com`

export const httpClient = axios.create({
    baseURL: `${getServerBaseUrl()}/api`,
})
