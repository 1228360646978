import axios from "axios"
import { v4 as uuid } from "uuid"
import _debug from "debug"
import { throwFatal } from "./utils/safeguards"
import * as peerRegistry from "./peer-registry"
import BackoffTracker from "./backoff-tracker"
import isEmpty from "lodash/isEmpty"
import once from "lodash/once"
import debounce from "lodash/debounce"
import {
    enqueueIceCandidate,
    addPendingIceCandidates,
} from "./ice-candidate-queue"
import * as CDC from "./p5/state"
import { RTCClientParams, sendWSMessage, sendWsMessageWithResponse, socket, wsReponseMap } from "./rpc-client"

import type {
    AllocateIdMessage,
    CollaboratorInfoMessage,
    OfferMessage,
    AnswerMessage,
    IceInfoMessage,
    CollabMessage,
    UserUpdateMessage,
    SessionError,
    RefreshParticipantListReq,
    CloseMessage,
    ParticipantListMessage,
    ProjectDetailsMessage,
    ResolveOffererReq,
    AddReactionMessage,
    BlocksLocallyCopiedMessage,
    ProjectUpdateNotification,
    SnapshotUpdateMessage,
    ReconnectSendChannel,
    ReconnectPeer,
    ReportUserMessage,
    SetDisplayNameMessage,
    MuteUserMessage,
    CollaboratorUser,
    ClusterDetailsMessage,
    UserRemovedMessage,
} from "coco-rtc-shared"
import {
    handleViewProject,
    visitedCollaboratorId,
    hoveredProject,
    receiveProjectUpdate,
    collaboratorIdToSnapshotMap,
    getProjectDataForInitialPayload,
    waitForSnapshot,
    isEmitEnqueued,
    emitProject,
    receiveProjectVisitUpdate,
    getLatestSnapshot,
    broadcastVisitedProjectMessage,
    ProjectData,
    setProjectData,
    pendingEmit,
    receiveVersionCreated,
    receiveVersionRejected,
} from "./project-data-coordinator"
import { Dispatcher } from "./actions"
const debug = _debug("coco-collab:client")
import { ensureCreatorTracked, ensureSelfTracked, vm } from "./vm"
import {
    supportAutoReconnect,
    supportRenegotiation,
} from "./initial-search-params"
import { ConType, Peer } from "./peer"
import { Deferred, rejectAfter, timeout } from "./utils/promise"
import { PeerState } from "./actions/space-actions"
import { isBlocksSpace, isCodeSpace } from "./space"
import { atom } from "./utils/store"
import { currentCollaboratorId, httpClient } from "."
import { FreezeUsernameMessage } from "coco-rtc-shared"
import { ClusterState, clusterDetails, ClusterSpaceInfo } from "./cluster"
import { sign } from "crypto"
import { MediasoupClient } from "./mediasoup-client"
import { sendMyMsgsToNewCollaborator } from "./data-channel-handler"
import { CocoLogger } from '@cocoplatform/coco-logger';
import { TransportOptions } from "mediasoup-client/lib/types"

type AsyncThunk = () => void | Promise<void>
type PeerConType = "primary" | "secondary"


export let canvas: HTMLCanvasElement | undefined
export let localCanvasStreamTrack: MediaStreamTrack | undefined
export let localAudioTrack: MediaStreamTrack | undefined

const frameRate = 25

// Local convention is that we are polite if we are initiating the connection

const backoffTracker = new BackoffTracker()

// For logging.

const trackReceiveThresholdMS = 10_000
const offerDispatchExpirationDurationMS = 5_000

interface PeerConnectionConfig {
    accountSid?: string // eg. "AC15711068f884386496867fd690f8a40a"
    dateCreated?: string // eg. "2022-03-03T14:47:13.000Z"
    password?: string // "SUMCKE05JSwntx/f2gShgaicbqZpyPJAKuHGfteAIK4="
    ttl?: string // "86400"
    username?: string // "7710e6e62907758ea453e954927875a537fae3be047a37bb8a837e4e296a1014",
    iceServers: TransportOptions['iceServers'] // eg. "stun:global.stun.twilio.com:3478?transport=udp",
}

let peerConnectionConfig: PeerConnectionConfig | undefined

export const loadPeerConnectionConfig = async () => {
    try {
        ; ({ data: peerConnectionConfig } = await httpClient.get(`/ice`))
        if (!peerConnectionConfig) return
        /* if (peerConnectionConfig.iceServers.length > 2) {

            // Limit the number of ice servers
            // Ref: https://stackoverflow.com/a/58227693
            const stunServers =
                peerConnectionConfig.iceServers?.filter((it) =>
                    it.url.match(/^stun:/)
                ) ?? []

            const turnServers =
                peerConnectionConfig.iceServers?.filter((it) =>
                    it.url.match(/^turn:/)
                ) ?? []

            const iceServers = stunServers
                .slice(1)
                .concat(turnServers)
                .slice(0, 2)

            console.log({
                selectedIceServers: iceServers,
                totalIceServers: peerConnectionConfig.iceServers,
            })

            peerConnectionConfig.iceServers = iceServers
        } */
    } catch (err) {
        console.error("Error loading peerConnectionConfig")
    }
}

export const setCanvas = async (c: HTMLCanvasElement) => {
    console.log(`Received canvas`)
    canvas = c
    reconnectCanvas()
}

export const reconnectCanvas = async () => {
    if (!canvas) return
    try {
        console.log("Capturing stream from canvas")
        const localVideoStream = captureStream(canvas)
        const tracks = localVideoStream!.getVideoTracks()
        if (tracks.length > 0) {
            localCanvasStreamTrack = tracks[0]
            if (client && client.joined) {
                client.enableVideo(localCanvasStreamTrack).catch(err => console.error("Failed to enable video with error", err));
            }
        } else {
            console.error("Failed to retrieve video stream from canvas")
        }
        if (peerRegistry.currentPeerId) {
            Dispatcher.setVideoTrack(
                peerRegistry.currentPeerId,
                localCanvasStreamTrack
            )
            Dispatcher.setVideoTrackStatus(peerRegistry.currentPeerId, true)
        }
    } catch (e) {
        console.error("Failed to capture track from canvas", e)
    }
}

const captureStream = (c: HTMLCanvasElement) => {
    try {
        return c.captureStream(frameRate)
    } catch (e: any) {
        // In FF, until getContext is invoked, captureStream doesn't work
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
        if (e.name === "NS_ERROR_NOT_INITIALIZED") {
            c.getContext("webgl", {
                alpha: false,
                stencil: true,
                antialias: false,
            })
            return c.captureStream(frameRate)
        }
    }
}

const getCollaboratorPayload = async (
    collaboratorId: string,
    type: CollaboratorInfoMessage["type"] = "new-collaborator"
): Promise<CollaboratorInfoMessage> => {
    const isCurrent = peerRegistry.currentPeerId === collaboratorId
    const peer = peerRegistry.getPeer(collaboratorId)
    return {
        id: collaboratorId,
        type,
        user: peer ? getPeerUser(peer) : undefined,
        personaId: peer?.personaId,
        projectData: await getProjectDataForInitialPayload(collaboratorId),
        image: isCurrent
            ? await getLatestSnapshot()
            : collaboratorIdToSnapshotMap.get(collaboratorId),
        isBroadcastingAudio: isCurrent
            ? !!localAudioTrack
            : peer?.isBroadcastingAudio,
        isBroadcastingCanvas: isCurrent
            ? !!localCanvasStreamTrack
            : peer?.isBroadcastingCanvas,
    }
}

const initCollaboratorRegistration = async (collaboratorId: string) => {
    await sendWSMessage(await getCollaboratorPayload(collaboratorId))
}

let supportReconnectBrokenConnection = true

export const disableBrokenConnectionHandling = () => {
    supportReconnectBrokenConnection = false
}

export const peerConnectivityStatus = atom<
    Record<string, Record<PeerConType, RTCPeerConnectionState>>
>({})

const updateConnectivityState = (
    collaboratorId: string,
    connectionState: "connected" | "disconnected",
    conType: PeerConType,
    // Needed because firefox doesn't support peerConnection.connectionState
) => {
    peerConnectivityStatus.update((old) => {
        const conState = connectionState
        if (!conState) return old
        return {
            ...old,
            [collaboratorId]: {
                ...old[collaboratorId],
                [conType]: conState,
            },
        }
    })
}


const attachTracks = (
    pc: RTCPeerConnection,
    collaboratorId: string,
) => {
    pc!.getTransceivers()[1].direction = 'sendrecv';
    if (localCanvasStreamTrack) {
        Dispatcher.updateCollaboratorStatus(
            `Adding canvas stream track (1)`,
            {
                collaboratorId,
            }
        )
        pc!.getTransceivers()[1].sender.replaceTrack(localCanvasStreamTrack);

    } else {
        Dispatcher.updateCollaboratorStatus(
            `Local video stream track missing`,
            {
                collaboratorId,
            }
        )
    }

    pc!.getTransceivers()[0].direction = 'sendrecv';
    if (localAudioTrack) {
        pc!.getTransceivers()[0].sender.replaceTrack(localAudioTrack);
    }
};



const receiveReportedUser = async (signal: ReportUserMessage) => {
    Dispatcher.notifyUserReported(signal.id, signal.targetId, signal.reasons)
}


export const initialIdDeferred = new Deferred<string>()

let isFirstId = true

const receiveId = async (
    signal: AllocateIdMessage,
    rtcClientParams: RTCClientParams
) => {
    CocoLogger.setSpaceId(signal.spaceId);

    if (signal.spaceSessionId) {
        CocoLogger.setSpaceSessionId(signal.spaceSessionId);
    }

    if (signal.username) {
        CocoLogger.setUsername(signal.username);
    }

    peerRegistry.setCurrentPeerId(signal.id)
    ensureSelfTracked()
    const currentPeer = peerRegistry.updatePeer({
        id: signal.id,
        userId: signal.userId,
        personaId: signal.personaId,
        spaceSessionId: signal.spaceSessionId,
        isLive: true,
        isReal: true,
        name: signal.username ?? undefined,
    })
    initialIdDeferred.resolve(signal.id)
    let visCId: string | null = null
    if (isFirstId) {
        visitedCollaboratorId.set(signal.id)
    } else if ((visCId = visitedCollaboratorId.get())) {
        broadcastVisitedProjectMessage(visCId)
    }

    if (signal.username) {
        Dispatcher.setUserName(signal.username)
    }

    if (signal.isHost) {
        Dispatcher.setHost(true)
    }

    if (signal.isManager) {
        Dispatcher.setManager(true)
    }

    // Primarily relevant for websocket reconnects
    if (signal.spaceSessionId && currentPeer.name && currentPeer.userType) {
        await initialIdDeferred.promise.then(() => {
            return broadcastUserUpdate({
                id: currentPeer.userId!,
                userName: currentPeer.name!,
                userType: currentPeer.userType,
            })
        })
    }

    if (signal.spaceUserConfig) {
        if (signal.spaceUserConfig.collaboratorChunks) {
            Dispatcher.setCollaboratorChunks(
                signal.spaceUserConfig.collaboratorChunks
            )
        }
        if (signal.spaceUserConfig.codeEditorSettings) {
            CDC.state.update((it) => ({
                ...it,
                settings: signal.spaceUserConfig.codeEditorSettings,
            }))
        }
    }

    Dispatcher.updateSpaceSession({
        spaceId: signal.spaceId,
        spaceTitle: signal.spaceTitle,
        spaceSessionId: signal.spaceSessionId,
        isLocked: signal.isLocked,
        isEnded: signal.isEnded,
        ownPeerId: signal.id,
        selectedPeerId: signal.spaceSessionId ? signal.id : undefined,
    })

    if (!signal.spaceSessionId) return

    if (isBlocksSpace()) {
        await waitForSnapshot(signal.id)
    }
    Dispatcher.updateCollaboratorStatus(
        `Received id. Local video track present: ${!!localCanvasStreamTrack}`,
        {
            collaboratorId: signal.id,
        }
    )
    Dispatcher.setCollaboratorId(signal.id)
    Dispatcher.viewCollaborator(signal.id)

    if (peerRegistry.currentPeerId && peerRegistry.currentPeerId != signal.id) {
        console.error(
            `Unexpected id change detected ${peerRegistry.currentPeerId} -> ${signal.id}`
        )
    }

    await Promise.all([
        loadPeerConnectionConfig(),
        initCollaboratorRegistration(signal.id),
    ]);
    Dispatcher.setVideoTrack(signal.id, localCanvasStreamTrack!)

    while (enqueuedCollabMessages.length > 0) {
        const signal = enqueuedCollabMessages.shift()
        console.log("Replaying collab message: ", signal)
        handleCollabMessage(signal!, rtcClientParams)
    }

    initializeMediasoupTransports();
}

export let client: MediasoupClient;

export async function initializeMediasoupTransports() {
    if (client) {
        client.close();

        // Previous track closed, reconnect a new track to the
        // canvas
        reconnectCanvas();
    }

    client = new MediasoupClient({ ws: socket as WebSocket })

    await client.join(peerConnectionConfig?.iceServers);

    if (localCanvasStreamTrack) {
        await client.enableVideo(localCanvasStreamTrack);
    }

    if (localAudioTrack) {
        await client.enableAudio(localAudioTrack);
    }
}

export const initialProjectDetailsDeferred = new Deferred<{
    id?: string
    hasSnapshot?: boolean
}>()



export const receiveProjectDetails = async (signal: ProjectDetailsMessage) => {
    peerRegistry.updatePeer({
        id: signal.id,
        projectId: signal.project?.id,
        personaId: signal.personaId,
        isLive: true,
    })

    const peer = peerRegistry.ensurePeer(signal.id)

    const peersRecord: Record<string, PeerState> = {}
    const projectId = signal.project?.id

    if (signal.project?.id) {
        peer.userType = "CREATOR"
        Dispatcher.setUserType("CREATOR")
        peersRecord[signal.id] = {
            project: {
                projectId: signal.project.id,
                version: signal.project.version,
            },
        }
    } else {
        peer.userType = "VIEWER"
        Dispatcher.setUserType("VIEWER")
    }

    // If the user is creator, the initial loaded project is the user's
    // own project. Otherwise, if the user is a viewer, then we chose
    // the first available snapshot.
    initialProjectDetailsDeferred.resolve(
        peer.userType == 'CREATOR' ? {
            id: projectId,
            hasSnapshot:
                !!projectId &&
                !!signal.projectSnapshots?.find((ps) => ps.projectId === projectId),
        } : {
            id: signal.projectSnapshots[0]?.id,
            hasSnapshot: signal.projectSnapshots.length > 0,
        })

    if (peer.spaceSessionId && peer.name && peer.userType) {
        broadcastUserUpdate({
            id: peer.userId!,
            userName: peer.name!,
            userType: peer.userType,
        })
    }

    let foundOwnSnapshot = false
    if (signal.projectSnapshots) {
        for (const ps of signal.projectSnapshots) {
            const projectData: ProjectUpdateNotification & ProjectData = {
                id: ps.peerId,
                projectId: ps.projectId,
                version: ps.id,
                snapshot: ps.snapshotDataUrl ?? undefined,
                isTemplate: ps.isTemplate,
                previewId: ps.previewId,
                // TODO Remove need for Casting & default
                sizeBytes: ps.sizeBytes ? Number(ps.sizeBytes) : 0,
            }
            peersRecord[ps.peerId] = { project: projectData }
            if (ps.projectId === signal.project?.id && !pendingEmit) {
                try {
                    foundOwnSnapshot = true
                    receiveProjectUpdate(projectData, {
                        isInitial: true,
                    })
                } catch (e) {
                    console.error("Failed to consume project snapshot", e)
                }
            }
            setProjectData(ps.peerId, projectData)
            if (ps.snapshotDataUrl) {
                collaboratorIdToSnapshotMap.set(ps.peerId, ps.snapshotDataUrl)
            }
            if (peer.spaceSessionId && ps.userId === peer.userId) {
                continue
            }
            const userType =
                ps.participantType ?? (ps.userId ? "CREATOR" : "TEMPLATE")
            const updatedPeer = peerRegistry.updatePeer({
                id: ps.peerId,
                personaId: ps.personaId,
                projectId: ps.projectId,
                spaceSessionId: ps.spaceSessionId,
                name: ps.userName ?? peerRegistry.usernamePlaceholder,
                userType,
                isLive: ps.isLive,
                isReal: !!ps.userId,
            })
            if (ps.userName || ps.personaId) {
                Dispatcher.addCollaborator({
                    id: ps.peerId,
                    name: ps.userName ?? undefined,
                    userType,
                    isLive: !!ps.isLive,
                    isReal: !!ps.userId,
                    personaId: ps.personaId,
                })
            }
            ensureCreatorTracked({
                personaId: updatedPeer.personaId,
                name: updatedPeer.name!,
                id: updatedPeer.id,
                isReal: !!ps.userId,
            })
        }
    }
    if (signal.reportedIds.length > 0) {
        Dispatcher.flagCollaborator(signal.reportedIds)
    }

    if (!foundOwnSnapshot) {
        if (isCodeSpace()) {
            CDC.initDefault()
        }
        if (isBlocksSpace() && !signal.project?.id) {
            const availableSnapshot = signal.projectSnapshots[0];
            // A default project would have been selected already
            // lets switch to one of the projects for which we have snapshot
            const availablePeerId = availableSnapshot?.peerId
            if (availablePeerId) {
                handleViewProject(availablePeerId);
            }
        }
    }
    Dispatcher.updateSpaceSession({
        ownPeerId: signal.id,
        peers: peersRecord,
    })
    if (
        !signal.project?.version &&
        isEmitEnqueued &&
        peer.spaceSessionId &&
        peer.userType !== "VIEWER"
    ) {
        emitProject()
    }
}

export const receiveClusterDetails = (signal: ClusterDetailsMessage) => {
    const spaces: Record<string, ClusterSpaceInfo | null> = {}
    for (const spaceId of signal.spaceIds) {
        spaces[spaceId] = null
    }
    clusterDetails.set({
        clusterId: signal.clusterId,
        spaces,
    })
}

export const receiveUserRemoved = (signal: UserRemovedMessage) => {
    peerRegistry.updatePeer({
        id: signal.id,
        isRemoved: true,
    })
    Dispatcher.removeCollaborator(signal.id)
}

const receiveCollaborator = async (
    signal: CollaboratorInfoMessage,
    isReconnecting: boolean = false,
    mayReuseConnection: boolean = true
) => {
    const peer = peerRegistry.updatePeer({
        id: signal.id,
        image: signal.image,
        personaId: signal.personaId,
        isCurrent: signal.id === peerRegistry.currentPeerId,
        isBroadcastingAudio: signal.isBroadcastingAudio,
        isBroadcastingCanvas: signal.isBroadcastingCanvas,
        isBroadcastingDisplay: signal.isBroadcastingDisplay,
        isLive: true,
        isReal: true,
    })
    if (!isReconnecting) {
        receiveUserUpdate({ ...signal, isLive: true })
    }
    if (signal.projectData) {
        receiveProjectUpdate({ id: signal.id, ...signal.projectData })
    }
    if (signal.image) {
        collaboratorIdToSnapshotMap.set(signal.id, signal.image)
        Dispatcher.setImage(signal.id, signal.image)
    }
    await sendMyMsgsToNewCollaborator(signal.id)
}

let participantListDeferred = new Deferred()

export const refreshParticipantList = async (
    signal: ParticipantListMessage,
    didListChange: boolean
) => {
    const currentPeerId = peerRegistry.ensureCurrentPeerId()
    await Promise.all(
        signal.participants.map(async (participant) => {
            const isCurrent = participant.peerId === currentPeerId
            if (!participant.userType || participant.userType === "CREATOR") {
                ensureCreatorTracked({
                    id: participant.peerId,
                    personaId: participant.personaId,
                    name: participant.userName ?? undefined,
                    isCurrent,
                    isReal: true,
                })
            }

            updateConnectivityState(participant.peerId, participant.isActive ? "connected" : "disconnected", "primary");
            const peer = peerRegistry.getPeer(participant.peerId)

            if (peer && participant.personaId) {
                peer.personaId = participant.personaId
            }

            if (isCurrent) return
            if (
                didListChange &&
                participant.userName &&
                participant.userType &&
                participant.userId
            ) {
                receiveUserUpdate({
                    id: participant.peerId,
                    visitedId: participant.visitedId ?? undefined,
                    isLive: participant.isActive,
                    user: {
                        id: participant.userId,
                        userName: participant.userName,
                        userType: participant.userType,
                    },
                })
            }
            if (!participant.isActive && peer?.isLive) {
                receiveClose({
                    type: "close",
                    id: peer.id,
                    isLocal: true,
                })
            }
        })
    )

    participantListDeferred.resolve(null)
}

export const receiveParticipantList = (() => {
    let lastRawSignal: string | undefined
    return (signal: ParticipantListMessage, rawSignal: string) => {
        const didPListChange = lastRawSignal !== rawSignal
        lastRawSignal = rawSignal
        refreshParticipantList(signal, didPListChange)
    }
})()

const enqueuedCollabMessages: CollabMessage[] = []

export const handleCollabMessage = async (
    signal: CollabMessage,
    rtcClientParams: RTCClientParams
) => {
    try {
        debug(
            "Received websocket message (%s) from collaborator %s: %O",
            signal.type,
            signal.id,
            signal
        )

        if (signal.messageId) {
            const existingResponse = wsReponseMap.get(signal.messageId);

            if (existingResponse) {
                existingResponse.resolve(signal);
                wsReponseMap.delete(signal.messageId);
                return;
            }
        }

        if (signal.type === "id") {
            await receiveId(signal, rtcClientParams)
            return
        }
        if (!peerRegistry.currentPeerId) {
            console.debug("Enqueuing collab message: ", signal)
            enqueuedCollabMessages.push(signal)
            return
        }
        switch (signal.type) {
            case "close":
                receiveClose(signal)
                return

            case "new-collaborator":
                await receiveCollaborator(signal)
                return

            case "user-update":
                receiveUserUpdate({ ...signal, isLive: true })
                return

            case "project-updated":
                await receiveProjectUpdate(signal)
                return

            case "visit-project":
                await receiveProjectVisitUpdate(signal)
                return

            case "add-reaction":
                await receiveNewReaction(signal)
                return

            case "update-snapshot":
                await receiveSnapshotUpdate(signal)
                return

            case "report-user":
                await receiveReportedUser(signal)
                return

            case "set-display-name":
                receiveSetDisplayName(signal)
                return

            case "freeze-username":
                receiveFreezeUsername(signal)
                return

            case "mute-user":
                receiveMuteUser(signal)
                return

            case "version-created":
                receiveVersionCreated(signal)
                return

            case "version-rejected":
                receiveVersionRejected(signal)
                return
        }
    } catch (e) {
        console.error("Error processing server message", signal!, e)
    }
}

export const receiveSessionError = async (signal: SessionError) => {
    if (signal.removeCollaborator && signal.id) {
        Dispatcher.removeCollaborator(signal.id)
    }
}


export const copyBlocksToLocalProject = async (blocks: any) => {
    if (visitedCollaboratorId.get() !== peerRegistry.currentPeerId) {
        await handleViewProject(peerRegistry.currentPeerId!)
        await timeout(500)
        await vm.shareBlocksToTarget(blocks, vm.editingTarget.id, false)
        vm.refreshWorkspace()
    }
}

export const copySpriteToLocalProject = async (sprite: any) => {
    if (visitedCollaboratorId.get() !== peerRegistry.currentPeerId) {
        await handleViewProject(peerRegistry.currentPeerId!)
        await vm.addSprite(sprite)
        vm.refreshWorkspace()
    }
}

// TODO Remove

export const acquireAudioTrack = async () => {
    const localAudioStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
            advanced: [
                {
                    echoCancellation: true,
                },
            ],
        },
    })
    if (!localAudioTrack) {
        localAudioTrack = localAudioStream.getAudioTracks()[0]
        if (!localAudioTrack) {
            throw Error("No audio track available")
        }
    }
    localAudioTrack.enabled = true
}

export const startMicOutput = async () => {
    await acquireAudioTrack();
    if (!localAudioTrack) {
        throw Error("No audio track available")
    }
    await client.enableAudio(localAudioTrack);
}


export const stopMicOutput = () => {
    if (!localAudioTrack) return
    return client.disableAudio(true);
}

const receiveUserUpdate = (signal: {
    id: string
    isLive: boolean
    user?: CollaboratorUser
    visitedId?: string
}) => {
    if (!signal.user) return
    const prevUserName = peerRegistry.getPeer(signal.id)?.name
    const peer = peerRegistry.updatePeer({
        id: signal.id,
        name: signal.user.userName,
        userType: signal.user.userType,
        isReal: true,
    })
    Dispatcher.addCollaborator({
        id: signal.id,
        name: signal.user.userName,
        userType: signal.user.userType,
        isLive: signal.isLive,
        isReal: true,
        personaId: peer.personaId,
    })
    if (signal.visitedId) {
        Dispatcher.updateVisitedId(signal.id, signal.visitedId)
    }
    if (
        prevUserName !== signal.user.userName &&
        signal.id !== peerRegistry.currentPeerId &&
        signal.user.userType === "CREATOR"
    ) {
        Dispatcher.enqueueEvent(
            `${signal.user.userName} joined the space`,
            "JoinSpace"
        )
    }
    if (signal.user.userType === "CREATOR") {
        ensureCreatorTracked({
            id: signal.id,
            name: signal.user.userName,
            isReal: true,
        })
    }
}

const receiveClose = (signal: CloseMessage) => {
    const peer = peerRegistry.getPeer(signal.id)
    if (!signal.isLocal) {
        Dispatcher.updateCollaboratorStatus(`Close event received`, {
            collaboratorId: signal.id,
        })
    }
    if (peer?.name && peer?.userType === "CREATOR") {
        Dispatcher.enqueueEvent(`${peer.name} left the space`, "LeaveSpace")
    }
    if (peer) {
        peer.isLive = false
    }
    Dispatcher.updateLiveStatus(signal.id, false)
    Dispatcher.setVideoTrack(signal.id, null)
    Dispatcher.setAudioTrack(signal.id, null)

    updateConnectivityState(signal.id, "disconnected", "primary")
    /* const creators: RuntimeCreatorEntry[] = vm.runtime.coco.creators
    const idx = creators.findIndex((it) => it.id === signal.id)
    if (idx >= 0) {
        creators.splice(idx, 1)
    } */
}

export const receiveNewReaction = async (signal: AddReactionMessage) => {
    const originPeer = peerRegistry.getPeer(signal.id)
    Dispatcher.enqueueReaction(signal.reactionType, signal.id, originPeer?.name)
}

export const receiveSnapshotUpdate = async (signal: SnapshotUpdateMessage) => {
    const peer = peerRegistry.getPeer(signal.id)
    if (peer) {
        peer.image = signal.image
    }
    collaboratorIdToSnapshotMap.set(signal.id, signal.image)
}

const getPeerUser = (peer: Peer): CollaboratorUser | undefined => {
    if (peer.userType && peer.name && peer.userId) {
        return {
            id: peer.userId,
            userName: peer.name,
            userType: peer.userType,
        }
    }
    return
}

export const broadcastUserUpdate = async (user: CollaboratorUser) => {
    await initialIdDeferred.promise
    const peerId = peerRegistry.ensureCurrentPeerId()

    // We need to dispatch addCollaborator here for viewers (in which case we won't have a space session)
    // and also to get rid of unknown in user label without having to wait for
    // server roundtrip
    //
    // This also ensures that we can start sending user information in
    // introductions etc. right after login form submission
    receiveUserUpdate({ id: peerId, user, isLive: true })

    Dispatcher.addCollaborator({
        id: peerId,
        name: user.userName,
        userType: user.userType,
        isLive: true,
        isReal: true,
    })
    const peer = peerRegistry.getCurrentPeer()
    if (peer && !peer.spaceSessionId) {
        return
    }
    const msg: UserUpdateMessage = {
        type: "user-update",
        user,
        id: peerId,
    }
    await sendWSMessage(msg)
}

const requestParticipantListRefresh = async () => {
    const msg: RefreshParticipantListReq = {
        type: "refresh-participant-list",
    }
    try {
        await sendWSMessage(msg)
        participantListDeferred.reset()
    } catch (e) {
        // This can fail if socket is still in connecting state
        // This is non-critical so retaining as a report
        console.error(e)
    }
}

const PARTICIPANT_LIST_REFRESH_INTERVAL_MS = 5_000
const PARTICIPANT_LIST_WAIT_DURATION_MS = 7_000

export const initRefreshLoop = once(async () => {
    while (true) {
        try {
            await Promise.race([
                participantListDeferred.promise.finally(),
                rejectAfter(PARTICIPANT_LIST_WAIT_DURATION_MS),
            ])
        } catch (err) {
            console.error("Timeout in refresh participant loop")
            socket?.close();
        }
        await requestParticipantListRefresh()
        await timeout(PARTICIPANT_LIST_REFRESH_INTERVAL_MS)
    }
})

export const notifyCopyBlocksToLocalProject = (blocks: any) => {
    const userId = peerRegistry.getCurrentPeer()?.userId
    const cId = visitedCollaboratorId.get()
    const projectId = cId && peerRegistry.getPeer(cId)?.projectId
    const id = peerRegistry.currentPeerId
    if (!projectId || !id || !userId) return
    const message: BlocksLocallyCopiedMessage = {
        type: "blocks-locally-copied",
        id,
        projectId,
        userId,
        blocks: JSON.stringify(blocks),
    }
    sendWSMessage(message, { maxAttempts: 3 })
}

export const receiveSetDisplayName = (signal: SetDisplayNameMessage) => {
    if (signal.id !== currentCollaboratorId) {
        alert("Host has updated your display name")
    }
    peerRegistry.updatePeer({
        id: peerRegistry.ensureCurrentPeerId(),
        name: signal.displayName,
    })
    Dispatcher.setUserName(signal.displayName)
    if (signal.freeze) {
        Dispatcher.setUsernameFrozen()
    }
}

export const receiveFreezeUsername = (signal: FreezeUsernameMessage) => {
    alert("Host has restricted you from updating your display name")
    Dispatcher.setUsernameFrozen()
}

export const receiveMuteUser = (signal: MuteUserMessage) => {
    alert("Host has muted you")
    Dispatcher.remoteMuteCollaborator()
}
