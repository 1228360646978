import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    success: {
      main: '#0EB050',
      contrastText: '#fff',
    },
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: 'rgba(243, 245, 240, 1)' },
    primary: {
      light: '#F9F7FF',
      main: '#986CFC',
      dark: '#7347D1',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(255, 171, 26, 1)', // #ffab1a
      main: 'rgba(255, 140, 26, 1)', // #ff8c1a
      dark: 'rgba(255, 102, 26, 1)', // #ff661a
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    /* text: {
      primary: 'rgba(255, 255, 255, 1)',
      secondary: '#575e75',
      disabled: 'rgba(0, 0, 0, 0.38)',
      // hint: 'rgba(0, 0, 0, 0.38)',
    }, */
  },
  typography: {
    fontFamily: `"Nunito", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    fontSize: 13,
  },
});
