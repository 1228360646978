// import type { Space } from "coco-rtc-shared"
import { Space } from "coco-rtc-shared"
import { atom } from "./utils/store"

export const spaceDetails = atom<null | Space>(null)

export const getSpaceType = () => spaceDetails.get()?.spaceType

export const isCodeSpace = () => getSpaceType() === "p5_text"

export const isCanvasSpace = () => getSpaceType() === "canvas"

export const isBlocksSpace = () => getSpaceType() === "scratch_blocks"
