import * as z from "zod"

export const OrganizationInfoSchema = z.object({
    id: z.string(),
    name: z.string(),
    website: z.string().nullish(),
})

export type OrganizationInfo = z.TypeOf<typeof OrganizationInfoSchema>

export const OrganizationInputSchema = z.object({
    name: z.string(),
    membershipKind: z.string(),
    targetIds: z.string().array().nullish(),
    website: z.string().optional(),
    country: z.string(),
    city: z.string().optional()
})

export type OrganizationInput = z.TypeOf<typeof OrganizationInputSchema>
