const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const MODAL_CAPTURE_SCREEN = 'captureScreenModal';

export const openModal = (modal: string, context?: any) => ({
    type: OPEN_MODAL,
    modal: modal,
    context
})

const openCaptureScreenModal = () =>
    openModal(MODAL_CAPTURE_SCREEN)

export const modalActionCreators = {
    openCaptureScreenModal,
}
