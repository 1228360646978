import React, { useEffect, useState } from 'react';
import PreLoginLayout from 'components/prelogin-layout/PreLoginLayout';
import CircularProgress from '@mui/material/CircularProgress';

export function LoaderBody(p: { showLoader: boolean }) {
  return (
    <div style={{ textAlign: 'center' }}>
      {p.showLoader && (
        <>
          <CircularProgress />
        </>
      )}
    </div>
  );
}

export default function FullPageLoaderFallback(p: { initialDelayMS?: number }) {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(showLoaderAfterTimeout, []);

  return (
    <PreLoginLayout footer={<div />}>
      <LoaderBody {...{ showLoader }} />
    </PreLoginLayout>
  );

  function showLoaderAfterTimeout() {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, p.initialDelayMS ?? 500);
    return () => clearTimeout(timer);
  }
}
