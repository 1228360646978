import {
    client,
    handleCollabMessage,
    initialIdDeferred,
    initRefreshLoop,
    receiveClusterDetails,
    receiveParticipantList,
    receiveProjectDetails,
    receiveUserRemoved,
} from "./rtc-handlers"
import qs from "qs"
import isString from "lodash/isString"
import type {
    CollabMessage,
    CreateConsumerResponse,
    ServerMessage,
    SpaceVarsMessage,
    WsQueryParams,
} from "coco-rtc-shared"
import { timeout } from "./utils/promise"
import { Dispatcher } from "./actions"
import { spaceVarsEmitter } from "./space-vars"
import { atom } from "./utils/store"
import { uniqueId } from "lodash"
import { sign } from "crypto"
import { onAddNewMessage, onMessageToCreator, onReplaceMessages, onSetSpaceVar, onWaveBroadcast } from "./data-channel-handler"
import { getServerBaseUrl } from "./http-client"
import { CocoLogger } from "@cocoplatform/coco-logger"

export let socket: WebSocket | undefined

export let spaceId: string | undefined

export interface RTCClientParams {
    spaceId: string
    authToken: string
    initialQueryParams: Record<string, any>
}

export const concludeSocket = () => {
    websocketStatus.set("concluded")
    socket?.close()
}

type WebsocketConnectionStatus =
    | "pending"
    | "active"
    | "pending-recovery"
    | "recovering"
    | "concluded"

export const websocketStatus = atom<WebsocketConnectionStatus>("pending")

let retryTimeout = 1000


const SOCKET_REQUEST_BLACKLIST = [
    "refresh-participant-list",
];


const SOCKET_RESPONSE_BLACKLIST = [
    "participant-list",
];

export const ensureSocketConnected = (params: RTCClientParams) => {
    spaceId = params.spaceId
    const queryParams: WsQueryParams = {
        room: params.spaceId,
        authToken: params.authToken,
    }
    const queryParamsStr = qs.stringify(queryParams)
    let wsUrl = `${getServerBaseUrl().replace(
        /^http/,
        "ws"
    )}/socket?${queryParamsStr}`

    CocoLogger.info("Websocket::Initialiizing", { url: wsUrl })
    socket = new WebSocket(wsUrl)
    initRefreshLoop()

    /* wsClient = createWSClient({
        url: wsUrl,
    })

    const socket = wsClient.getConnection() */

    // Used to sequence handling of messages received from server
    let socketEvtHandlerPromise = Promise.resolve()

    const handleSocketMessage = async (msg: MessageEvent) => {
        let signal: ServerMessage | undefined
        try {
            signal = JSON.parse(msg.data)
        } catch (e) {
            console.error("Failed to parse server message")
        }
        if (!signal) return
        if (signal.type === "id") {
            // Reset the retry timeout
            retryTimeout = 1000
        }

        // Disable response logging for now, due to too much traffic
        // if (!SOCKET_RESPONSE_BLACKLIST.includes(signal.type)) {
        //     CocoLogger.info("Websocket::handleSocketMessage", { type: signal.type })
        // }

        switch (signal.type) {
            case "auth-error": {
                websocketStatus.set("concluded")
                Dispatcher.receiveAuthError(signal)
                return
            }
            case "access-error": {
                websocketStatus.set("concluded")
                Dispatcher.receiveAccessError(signal)
                return
            }
            case "processing-error": {
                alert(signal.message)
                return
            }
            case "session-error": {
                websocketStatus.set("concluded")
                Dispatcher.receiveSessionError(signal)
                return
            }
            case "participant-list": {
                receiveParticipantList(signal, msg.data)
                return
            }
            case "project-details": {
                await receiveProjectDetails(signal)
                return
            }
            case "space-cluster-details": {
                await receiveClusterDetails(signal)
                return
            }
            case "host-awaited": {
                Dispatcher.setHostAwaited()
                return
            }
            case "make-co-host": {
                socket?.close() // Will auto reconnect
                alert("You have been made a co-host of this space")
                return
            }
            case "block-reported-user":
            case "remove-user": {
                socket?.close()
                alert("You have been blocked from this space")
                return
            }
            case "user-removed": {
                receiveUserRemoved(signal)
                return
            }
            case "new-consumer": {
                try {
                    client.newConsumer({
                        ...signal,
                        type: signal.consumerType, // re-assign 'type' parameter correctly
                        ackId: signal.messageId as string,
                    })
                } catch (err) {
                    console.error("Failed to create new consumer with error", err)
                }
                return
            }
            case "consumer-resumed": {
                try {
                    client.consumerResumed({
                        consumerId: signal.consumerId,
                    });
                } catch (err) {
                    console.error("Failed to resume consumer with error", err)
                }
                return;
            }
            case "consumer-closed": {
                try {
                    client.consumerClosed({
                        consumerId: signal.consumerId,
                    });
                } catch (err) {
                    console.error("Failed to close consumer with error", err)
                }
                return;
            }
            case "sendMessageToCreator": {
                onMessageToCreator(signal);
                return;
            }
            case "set-space-var": {
                onSetSpaceVar(signal);
                return;
            }
            case "add-new-message": {
                onAddNewMessage(signal);
                return;
            }
            case "replace-messages": {
                onReplaceMessages(signal);
                return;
            }
            case "wave-broadcast": {
                onWaveBroadcast(signal);
                return;
            }
        }
        if (signal.type.match(/-space-var$/)) {
            spaceVarsEmitter.post(signal as SpaceVarsMessage)
            return
        }
        await handleCollabMessage(signal as CollabMessage, params)
    }

    const handleSocketClose = async (evt: CloseEvent) => {
        if (websocketStatus.get() === "concluded") {
            return
        }
        console.error(
            `Websocket connection closed: Will reattempt after ${retryTimeout}ms`,
            evt
        )
        await timeout(retryTimeout)
        retryTimeout = retryTimeout * 2
        CocoLogger.info("Websocket::Reconnecting")
        websocketStatus.set("recovering")
        initialIdDeferred.reset()
        ensureSocketConnected(params)
    }

    socket.addEventListener("open", () => {
        websocketStatus.set("active")
        CocoLogger.info("Websocket::Connected")

    })

    socket.addEventListener("message", (event: MessageEvent) => {
        socketEvtHandlerPromise = socketEvtHandlerPromise.finally(() =>
            handleSocketMessage(event)
        )
    })

    socket.addEventListener("close", (event: CloseEvent) => {
        if (websocketStatus.get() !== "concluded") {
            websocketStatus.set("pending-recovery")
        }
        CocoLogger.info("Websocket::Disconnected")

        socketEvtHandlerPromise = socketEvtHandlerPromise.finally(() =>
            handleSocketClose(event)
        )
    })
}

const WS_SEND_MAX_ATTEMPTS = 100

interface WSDispatchOpts {
    maxAttempts?: number
}


export const wsReponseMap: Map<string, { resolve: Function, reject: Function }> = new Map();

export const sendWsMessageWithResponse = async <T>(event: T & { messageId?: string, type?: string }, opts?: WSDispatchOpts) => {
    event.messageId = uniqueId("socket");

    if (!SOCKET_REQUEST_BLACKLIST.includes(event.type as string)) {
        CocoLogger.info("Websocket::sendWsMessageWithResponse", { type: event.type })
    }
    return new Promise<any>(async (resolve, reject) => {
        wsReponseMap.set(event.messageId as string, { resolve, reject });
        try {
            await sendWSMessage(event, opts);
        } catch (e) {
            reject(e);
            wsReponseMap.delete(event.messageId as string);
        }
    })
}

export const sendWSMessage = async <T>(event: T & { type?: string }, opts?: WSDispatchOpts) => {
    if (!SOCKET_REQUEST_BLACKLIST.includes(event.type as string)) {
        CocoLogger.info("Websocket::sendWsMessage", { type: event.type })
    }
    const message = isString(event) ? event : JSON.stringify(event)
    let lastErr
    const maxAttempts = opts?.maxAttempts ?? WS_SEND_MAX_ATTEMPTS
    for (let i = 0; i < maxAttempts; i++) {
        if (websocketStatus.get() === "concluded") return
        if (!socket || socket.readyState !== 1) {
            console.debug(
                `[A: ${i}] Websocket not ready (RS: ${socket?.readyState}) - Deferring!`
            )
            await timeout(500)
            continue
        }
        try {
            socket.send(message)
            return
        } catch (e) {
            console.error("Failed to send ws message: ", e, message)
            lastErr = e
        }
    }
    if (lastErr) throw lastErr
}
