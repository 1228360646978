import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ROUTES from 'constants/routes';
import PasscodePage from 'components/passcode-page/PasscodePage';
import { useInitialQParams } from 'utils/extracte-initial-qparams';
import { useAuthState, useAuthStateEffect } from 'utils/auth-state';
import DefaultPage from 'components/default-page/DefaultPage';
import { ResumableRedirect } from 'components/resumable-redirect/ResumableRedirect';
import { useUserProfile } from 'atoms/user-profile';
import { useRequestLogger, useRouterLogger } from 'utils/router-logger';
import { useThemeSwitcher } from 'utils/theme-switcher';
import { useFlagTraitEffect } from 'utils/flag-traits';

const EffectsExplorer = React.lazy(
  () => import('components/effects-explorer/EffectsExplorer'),
);

const LocationSetupPage = React.lazy(
  () => import('components/location-setup-page/LocationSetupPage'),
);

const LimitedConsumerEntryForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LimitedConsumerEntryForm" */ 'components/limited-consumer-entry-form/LimitedConsumerEntryForm'
    ),
);

const PreLoginNavBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostLoginNavBar" */ 'components/prelogin-nav-bar/PreLoginNavBar'
    ),
);

const CommunityDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityDashboard" */ 'components/community-dashboard/CommunityDashboard'
    ),
);

const PreLoginLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreLoginLayout" */ 'components/prelogin-layout/PreLoginLayout'
    ),
);

const LoginKeyJoinPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginKeyJoinPage" */ 'components/login-key-join-page/LoginKeyJoinPage'
    ),
);

const DashboardLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardLayout" */ 'components/dashboard-layout/DashboardLayout'
    ),
);

const PostLoginNavBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostLoginNavBar" */ 'components/post-login-nav-bar/PostLoginNavBar'
    ),
);

const SharedLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SharedLayout" */ 'components/shared-layout/SharedLayout'
    ),
);

const PreLoginCommunityLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreLoginCommunityLayout" */ 'components/prelogin-community-layout/PreLoginCommunityLayout'
    ),
);

const SpaceEditorPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SpaceEditorPage" */ 'components/space-editor-page/SpaceEditorPage'
    ),
);

const CommunityMemberAdder = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityMemberAdder" */ 'components/community-member-adder/CommunityMemberAdder'
    ),
);

const CommunityMembersExplorer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunityMembersExplorer" */ 'components/community-members-explorer/CommunityMembersExplorer'
    ),
);

const SpaceExplorerBody = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SpaceExplorerBody" */ 'components/space-explorer-body/SpaceExplorerBody'
    ),
);

const WelcomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WelcomePage" */ 'components/welcome-page/WelcomePage'
    ),
);

const SignUpPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpPage" */ 'components/sign-up-page/SignUpPage'
    ),
);

const SignInPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignInPage" */ 'components/sign-in-page/SignInPage'
    ),
);

const ProfileUpdatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileUpdatePage" */ 'components/profile-update-page/ProfileUpdatePage'
    ),
);

const CommunitySetupInitPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunitySetupInitPage" */ 'components/community-setup/CommunitySetupInitPage'
    ),
);

const CommunitySetupIntentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunitySetupIntentPage" */ 'components/community-setup/CommunitySetupIntentPage'
    ),
);

const JoinCodePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "JoinCodePage" */ 'components/join-code/JoinCodePage'
    ),
);

const PrintableUserList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrintableUserList" */ 'components/printable-user-list/PrintableUserList'
    ),
);

const ProfilePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProfilePage" */ 'components/profile-page/ProfilePage'
    ),
);

const PostPasswordResetPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostPasswordResetPage" */ 'components/post-password-reset-page/PostPasswordResetPage'
    ),
);

const SpaceDetailsModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SpaceDetailsModal" */ 'components/space-details-modal/SpaceDetailsModal'
    ),
);

const MediaLibrary = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MediaLibrary" */ 'components/media-library/MediaLibrary'
    ),
);

const PrivacyPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPage" */
      'components/privacy-page/PrivacyPage'
    ),
);

const TermsOfUsePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUserPage" */
      'components/terms-page/TermsPage'
    ),
);

const EmailUpdatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailUpdatePage" */
      'components/email-update-page/EmailUpdatePage'
    ),
);

const SurveyExplorer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SurveyExplorer" */
      'components/survey-explorer/SurveyExplorer'
    ),
);

const PostUnsubscribePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PostUnsubscribePage" */
      'components/post-unsubscribe-page/PostUnsubscribePage'
    ),
);

const CommunitySettings = React.lazy(
  () => import('components/community-settings/CommunitySettings'),
);

const RouteHandler: React.FC = () => {
  const { didSetPasscode, savedToken, isProfileUpdated } = useAuthState();
  useInitialQParams();
  useAuthStateEffect();
  useRouterLogger();
  useRequestLogger();
  useThemeSwitcher();
  useFlagTraitEffect();

  // We care about profile being available because
  // a. userId is used in cache keys
  // b. Through this req. we validate that user is still signed
  //    in and prevent a brief flicker of dashboard before
  //    user is redirected to sign in page
  const { fetchUserProfile, userProfile } = useUserProfile();

  useEffect(() => {
    if (!savedToken) return;
    if (!userProfile) {
      fetchUserProfile();
    }
  }, [savedToken]);

  if (REQUIRE_PASSCODE) {
    if (!didSetPasscode) {
      return (
        <Switch>
          <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
            <PostUnsubscribePage />
          </Route>
          <Route exact path={ROUTES.PASSCODE}>
            <PasscodePage />
          </Route>
          <Route exact path={ROUTES.PRIVACY_TERMS}>
            <PrivacyPage />
          </Route>
          <Route exact path={ROUTES.TERMS_OF_USE}>
            <TermsOfUsePage />
          </Route>
          <Route>
            <ResumableRedirect to={ROUTES.PASSCODE} />
          </Route>
        </Switch>
      );
    }
  }

  if (!PAGES_APP_ENABLED) {
    return (
      <Switch>
        <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
          <PostUnsubscribePage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUsePage />
        </Route>
        {/* / is not served through react-router */}
        <Route>
          <ResumableRedirect to='/' />
        </Route>
      </Switch>
    );
  }

  if (!savedToken) {
    if (LIMITED_CONSUMER_MODE) {
      return (
        <Switch>
          <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
            <PostUnsubscribePage />
          </Route>
          <Route exact path={ROUTES.PRIVACY_TERMS}>
            <PrivacyPage />
          </Route>
          <Route exact path={ROUTES.TERMS_OF_USE}>
            <TermsOfUsePage />
          </Route>
          <Route exact path={ROUTES.CORRIDOR_FORM}>
            <LimitedConsumerEntryForm />
          </Route>
          <Route exact path={ROUTES.SPACE_FORM}>
            <LimitedConsumerEntryForm />
          </Route>
          <Route exact path={ROUTES.ROOT}>
            <LimitedConsumerEntryForm />
          </Route>
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
          <PostUnsubscribePage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUsePage />
        </Route>
        <Route exact path={ROUTES.SIGN_IN}>
          <PreLoginLayout>
            <SignInPage />
          </PreLoginLayout>
        </Route>
        <Route exact path={ROUTES.SIGN_UP}>
          <PreLoginLayout>
            <SignUpPage />
          </PreLoginLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE}>
          <PreLoginCommunityLayout>
            <JoinCodePage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_USER_CODE}>
          <PreLoginCommunityLayout>
            <LoginKeyJoinPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_WITH_KEY}>
          <PreLoginCommunityLayout>
            <LoginKeyJoinPage />
          </PreLoginCommunityLayout>
        </Route>
        <Route exact path={ROUTES.WELCOME}>
          <WelcomePage />
        </Route>
        <Route>
          <DefaultPage defaultRoute={ROUTES.WELCOME} />
        </Route>
      </Switch>
    );
  }

  /* if (LIMITED_CONSUMER_MODE) {
    return (
      <Switch>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.SPACE_EDITOR}>
          <SpaceEditorPage />
        </Route>
        <Route path={ROUTES.LANDING}>
          <LimitedConsumerEntryForm />
        </Route>
        <Route>
          <DefaultPage defaultRoute={ROUTES.LANDING} />
        </Route>
      </Switch>
    );
  } */

  if (!isProfileUpdated && !LIMITED_CONSUMER_MODE) {
    return (
      <Switch>
        <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
          <PostUnsubscribePage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_TERMS}>
          <PrivacyPage />
        </Route>
        <Route exact path={ROUTES.LOC_SETUP}>
          <SharedLayout header={<PostLoginNavBar />} wide>
            <LocationSetupPage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.ORG_SETUP}>
          <SharedLayout
            header={<PostLoginNavBar />}
            body={<CommunitySetupIntentPage />}
          />
        </Route>
        <Route exact path={ROUTES.EMAIL_UPDATE}>
          <SharedLayout header={<PostLoginNavBar />}>
            <EmailUpdatePage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.PROFILE_UPDATE}>
          <SharedLayout header={<PreLoginNavBar />}>
            <ProfileUpdatePage />
          </SharedLayout>
        </Route>
        <Route exact path={ROUTES.JOIN_CODE_PROFILE_UPDATE}>
          <PreLoginCommunityLayout colBodyProps={{ layout: 'narrow' }}>
            <ProfileUpdatePage />
          </PreLoginCommunityLayout>
        </Route>
        <Route>
          <DefaultPage defaultRoute={ROUTES.PROFILE_UPDATE} timeout={1000} />
        </Route>
      </Switch>
    );
  }

  if (!userProfile && !LIMITED_CONSUMER_MODE) {
    return null;
  }

  return (
    <Switch>
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.CORRIDOR_FORM}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.SPACE_FORM}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.ROOT}>
          <LimitedConsumerEntryForm />
        </Route>
      ) : undefined}
      {!LIMITED_CONSUMER_MODE ? (
        <Route exact path={ROUTES.EMAIL_UPDATE}>
          <SharedLayout header={<PostLoginNavBar />}>
            <EmailUpdatePage />
          </SharedLayout>
        </Route>
      ) : undefined}
      <Route exact path={ROUTES.POST_UNSUBSCRIBE}>
        <PostUnsubscribePage />
      </Route>
      <Route exact path={ROUTES.PRIVACY_TERMS}>
        <PrivacyPage />
      </Route>
      <Route exact path={ROUTES.TERMS_OF_USE}>
        <TermsOfUsePage />
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SETTINGS}>
        <DashboardLayout>
          <CommunitySettings />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD}>
        <DashboardLayout>
          <CommunityDashboard />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_INVITATION}>
        <DashboardLayout isOnboarding>
          <CommunityDashboard isInvited={true} />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACE}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
        <Suspense fallback={null}>
          <SpaceDetailsModal />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACE_EDIT}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
        <Suspense fallback={null}>
          <SpaceDetailsModal isEditing />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_SPACES}>
        <DashboardLayout>
          <SpaceExplorerBody />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_NEW_MEMBER}>
        <DashboardLayout>
          <CommunityMemberAdder />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_DASHBOARD_MEMBERS}>
        <DashboardLayout>
          <CommunityMembersExplorer />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.MEDIA_LIB_SECTION}>
        <DashboardLayout>
          <MediaLibrary />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.MEDIA_LIB}>
        <DashboardLayout>
          <MediaLibrary />
        </DashboardLayout>
      </Route>
      <Route exact path={ROUTES.DASHBOARD}>
        <Redirect to='/app/dashboard/$all' />
      </Route>
      <Route exact path={ROUTES.JOIN_CODE}>
        <PreLoginCommunityLayout>
          <JoinCodePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_USER_CODE}>
        <PreLoginCommunityLayout>
          <LoginKeyJoinPage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.JOIN_CODE_WITH_KEY}>
        <PreLoginCommunityLayout>
          <LoginKeyJoinPage />
        </PreLoginCommunityLayout>
      </Route>

      <Route exact path={ROUTES.JOIN_CODE_POST_COMPLETION}>
        <PreLoginCommunityLayout>
          <JoinCodePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.PRINTABLE_USER_LIST}>
        <PrintableUserList />
      </Route>
      <Route exact path={ROUTES.SPACE_EDITOR}>
        <SpaceEditorPage />
      </Route>
      {!LIMITED_CONSUMER_MODE && (
        <Route exact path={ROUTES.PROFILE_UPDATE}>
          <SharedLayout header={<PostLoginNavBar />}>
            <ProfileUpdatePage />
          </SharedLayout>
        </Route>
      )}
      <Route exact path={ROUTES.JOIN_CODE_PROFILE_UPDATE}>
        <PreLoginCommunityLayout colBodyProps={{ layout: 'narrow' }}>
          <ProfileUpdatePage />
        </PreLoginCommunityLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_SETUP_INIT}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupInitPage />}
        ></SharedLayout>
      </Route>
      <Route exact path={ROUTES.COMMUNITY_SETUP_INTENT}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupIntentPage />}
        />
      </Route>
      <Route exact path={ROUTES.LOC_SETUP}>
        <SharedLayout header={<PostLoginNavBar />} wide>
          <LocationSetupPage />
        </SharedLayout>
      </Route>
      <Route exact path={ROUTES.ORG_SETUP}>
        <SharedLayout
          header={<PostLoginNavBar />}
          body={<CommunitySetupIntentPage />}
        />
      </Route>
      {!LIMITED_CONSUMER_MODE && (
        <Route exact path={ROUTES.PROFILE}>
          <ProfilePage />
        </Route>
      )}
      <Route exact path={ROUTES.POST_PASSWORD_RESET}>
        <PostPasswordResetPage />
      </Route>
      <Route exact path={ROUTES.ADMIN_SURVEYS_EXPLORER}>
        <SurveyExplorer />
      </Route>
      {ENABLE_EFFECT_MANAGER && (
        <Route exact path={ROUTES.ADMIN_EFFECTS_EXPLORER}>
          <EffectsExplorer />
        </Route>
      )}
      <Route>
        <DefaultPage
          defaultRoute={LIMITED_CONSUMER_MODE ? ROUTES.ROOT : ROUTES.DASHBOARD}
        />
      </Route>
    </Switch>
  );
};

export default RouteHandler;
