import { CocoLogger } from '@cocoplatform/coco-logger';
import { httpClient } from 'coco-rtc-client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useRouterLogger = () => {
  const location = useLocation();

  useEffect(() => {
    CocoLogger.info(`Router::PageNavigation`, {
      url: location.pathname,
    });
  }, [location]);
};

export const useRequestLogger = () => {
  useEffect(() => {
    httpClient.interceptors.response.use(
      (response) => {
        try {
          if (response?.config.url !== CocoLogger.logsEndpoint) {
            CocoLogger.debug('xhr::axios', {
              url: response?.config.url,
              networkCall: {
                status: response?.status,
                statusText: response?.statusText,
                baseURL: response?.config.baseURL,
                method: response?.config.method,
              },
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('xhr::error', {
            error: 'responseInterceptorFailed',
            err,
            response,
          });
        }
        return response;
      },
      async (error) => {
        try {
          if (!error) {
            return Promise.reject(new Error('Unknown error occurred'));
          }


          if (
            error &&
            error.config &&
            error.config?.url !== CocoLogger.logsEndpoint
          ) {
            CocoLogger.error('xhr::axios', {
              error,
              url: error.config.url,
              networkCall: {
                status: error.status,
                statusText: error.statusText,
                baseURL: error.config.baseURL,
                retries: error.config.retry,
                method: error.config.method,
                isOnline: navigator.onLine ? 'online' : 'offline',
              },
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('xhr::error', {
            error: 'responseInterceptorFailed',
            err,
            responseError: error,
          });
          return Promise.reject(err);
        }

        return Promise.reject(error);
      },
    );
  }, []);
};
