import transform from "lodash/transform"
import { Evt } from "evt"

import {
    CollaboratorAction,
    collaboratorActionCreators,
    Types as CollaboratorActionTypes,
} from "./collaborator-actions"

import {
    SpaceAction,
    spaceActionCreators,
    Types as SpaceActionTypes,
} from "./space-actions"

import {
    ProjectAction,
    projectActionCreators,
    Types as ProjectActionTypes,
} from "./project-actions"

import {
    ErrorAction,
    errorActionCreators,
    Types as ErrorActionTypes,
} from "./error-actions"

import {
    modalActionCreators,
} from "./modal-actions"

export const ActionTypes = {
    ...CollaboratorActionTypes,
    ...SpaceActionTypes,
    ...ProjectActionTypes,
    ...ErrorActionTypes,
}

export const actionCreators = {
    ...spaceActionCreators,
    ...collaboratorActionCreators,
    ...projectActionCreators,
    ...errorActionCreators,
    ...modalActionCreators,
}

export const actionEmitter = new Evt<
    CollaboratorAction | ProjectAction | SpaceAction | ErrorAction
>()

declare const ENABLE_DEBUG_COLLABORATOR_TIMELINE: boolean

export const Dispatcher = transform(
    actionCreators,
    (result: any, fn: any, key: any) => {
        if (key === "updateCollaboratorStatus") {
            result[key] = (
                message: string,
                opts: { collaboratorId?: string }
            ) => {
                console.log(`CStatusUpdate (${opts.collaboratorId}): `, message)
                if (!ENABLE_DEBUG_COLLABORATOR_TIMELINE) {
                    actionEmitter.post(fn(message, opts))
                }
            }
        } else {
            result[key] = (...args: any[]) => {
                actionEmitter.post(fn(...args))
            }
        }
    },
    {}
) as {
    [K in keyof typeof actionCreators]: (
        ...args: Parameters<(typeof actionCreators)[K]>
    ) => void
}
