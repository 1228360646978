// routes for client-side routing
const ROUTES = {
  ROOT: '/',

  // Landing routes for limited consumer mode:
  // (app prefix not needed because home page is not present)
  CORRIDOR_FORM: '/corridor/:communityCode',
  SPACE_FORM: '/space/:spaceCode',

  // App routes:
  PRIVACY_TERMS: '/app/privacy-terms',
  TERMS_OF_USE: '/app/terms-of-use',
  LANDING: '/app/',
  WELCOME: '/app/welcome',
  PASSCODE: '/app/passcode',
  SIGN_UP: '/app/signup',
  SIGN_IN: '/app/signin',
  PROFILE_UPDATE: '/app/profile/update',
  EMAIL_UPDATE: '/app/user/email/update',
  ABOUT: '/app/about',
  LOGIN: '/app/login',
  HOME: '/app/home',
  HOME_TAB: '/app/home/:tabId',
  JOIN_CODE: '/app/join/:code',
  JOIN_CODE_POST_COMPLETION: '/app/join/:code/complete',
  JOIN_CODE_WITH_KEY: '/app/join/:code/loginkey',
  JOIN_CODE_PROFILE_UPDATE: '/app/join/:code/profile/update',
  JOIN_USER_CODE: '/app/join/:code/u/:username',
  COMMUNITY_HOME: '/app/communities/:id/:tabId',
  COMMUNITY_HOME_NESTED: '/app/communities/:id/:tabId/:sectionId',
  SPACE_EDITOR: '/app/spaces/:id/editor',
  ACCOUNT_SETUP: '/app/account/setup',
  COMMUNITY_SETUP_INIT: '/app/community/setup',
  COMMUNITY_SETUP_INTENT: '/app/community/setup/intent',
  LOC_SETUP: '/app/loc/setup',
  ORG_SETUP: '/app/org/setup',
  COMMUNITY: '/app/community/:id',
  PROFILE: '/app/profile',
  POST_PASSWORD_RESET: '/app/password-reset/post-submit',
  PRINTABLE_USER_LIST: '/app/communities/:id/user-list/printable',
  COMMUNITY_DASHBOARD: '/app/dashboard/:communityId',
  COMMUNITY_DASHBOARD_SETTINGS: '/app/dashboard/:communityId/settings',
  COMMUNITY_DASHBOARD_INVITATION: '/app/dashboard/:communityId/invitation',
  COMMUNITY_DASHBOARD_SPACES: '/app/dashboard/:communityId/spaces',
  COMMUNITY_DASHBOARD_SPACE: '/app/dashboard/:communityId/spaces/:spaceId',
  COMMUNITY_DASHBOARD_SPACE_EDIT:
    '/app/dashboard/:communityId/spaces/:spaceId/edit',
  COMMUNITY_DASHBOARD_MEMBERS: '/app/dashboard/:communityId/members',
  COMMUNITY_DASHBOARD_NEW_MEMBER:
    '/app/dashboard/:communityId/members/new/:sectionId?',
  DASHBOARD: '/app/dashboard',
  MEDIA_LIB: '/app/dashboard/:communityId/media-lib',
  MEDIA_LIB_SECTION: '/app/dashboard/:communityId/media-lib/:sectionId',
  ADMIN_SURVEYS_EXPLORER: '/app/admin/surveys',
  ADMIN_EFFECTS_EXPLORER: '/app/admin/effects',
  POST_UNSUBSCRIBE: '/app/post-unsubscribe',
};

export interface DashboardParams {
  communityId?: string;
  sectionId?: string;
}

export default ROUTES;
