import * as peerRegistry from "./peer-registry"
import omit from "lodash/omit"
import isEqual from "lodash/isEqual"
import { sendWSMessage } from "."

import { NewMessageAddBroadcast, ReplaceMessagesBroadcast, SendMessageToCreator, WaveBroadcast } from "coco-rtc-shared"


// Will be set to the instance of scratch-vm used by scratch-gui.
export let vm: any | undefined

export interface RuntimeCreatorEntry {
    id: string
    name?: string
    isReal: boolean
    personaId?: string
    isCurrent?: boolean
}

export const setVM = (vmInstance: any) => {
    vm = vmInstance
    vm.runtime.on("PROJECT_START", () => {
        sendWSMessage<WaveBroadcast>({
            type: 'wave-broadcast',
            id: peerRegistry.currentPeerId!,
        });
    })
    vm.runtime.on("sendMessageToCreator", (message: any, id: string) => {
        sendWSMessage<SendMessageToCreator>({
            id: peerRegistry.currentPeerId!,
            data: message,
            targetId: id,
            type: 'sendMessageToCreator',
        });
    })

    vm.runtime.on("MESSAGE_ADDED", (message: string, id: string) => {
        if (id === peerRegistry.currentPeerId) {
            sendWSMessage<NewMessageAddBroadcast>({
                type: "add-new-message",
                id: peerRegistry.currentPeerId,
                data: message,
            })
        }
    })

    vm.runtime.on("MESSAGES_REPLACED", (messages: string[], id: string) => {
        if (id === peerRegistry.currentPeerId) {
            sendWSMessage<ReplaceMessagesBroadcast>({
                type: 'replace-messages',
                id: peerRegistry.currentPeerId,
                data: messages,
            });
        }
    })

    ensureSelfTracked()
}

export const ensureSelfTracked = () => {
    const { currentPeerId } = peerRegistry
    if (!currentPeerId || !vm) return
    vm.runtime.coco ??= {}
    vm.runtime.coco.currentPeerId = currentPeerId
    if (!vm.runtime.coco.messages.get(currentPeerId)) {
        vm.runtime.coco.messages.set(currentPeerId, ["signal1"])
    }
}

export const ensureCreatorTracked = (entry: RuntimeCreatorEntry) => {
    if (!vm) return
    let creators: RuntimeCreatorEntry[] = vm.runtime.coco?.creators ?? []
    const previousCreatorIdx = creators.findIndex((it) => {
        return it.id === entry.id
    })
    if (entry.name === peerRegistry.usernamePlaceholder || !entry.name) {
        entry = omit(entry, "name")
    }
    if (previousCreatorIdx >= 0) {
        const updated = { ...creators[previousCreatorIdx], ...entry }
        if (isEqual(updated, creators[previousCreatorIdx])) {
            return // No change - prevent expensive workspace emit
        }
        creators[previousCreatorIdx] = updated
    } else {
        creators.unshift(entry)
    }
    vm.runtime.coco ??= {}
    vm.runtime.coco.creators = creators
    console.log("vm.runtime.coco.creators: ", creators)
    vm.runtime.requestBlocksUpdate()
}
